export const initPlacePrediction = () => {
  return (valueis, searchOptions, callback) => {
    const autocomplete = window.autocompleteService;
    if (valueis && valueis.length > 3) {
      autocomplete.getPlacePredictions(
        {
          ...searchOptions,
          input: valueis,
          offset: 3
        },
        (predictions, status) => {
          callback(predictions, status);
        }
      );
    }
  };
};
