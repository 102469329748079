import React from "react";
import { StyleSheet, View } from "react-native";
import SText from "styled42/components/SText";
import HTag from "styled42/components/HTag";
import generateUniqueKey from "utils/generateUniqueKey";
import NavLink from "utils/routing/NavLink";

const gridConstructor = (row, column, array) => {
  const array2d = [];
  for (let i = 0; i < row; i += 1) {
    array2d[i] = [];
    for (let j = 0; j < column; j += 1) {
      array2d[i][j] = array[i * column + j];
    }
  }
  return array2d;
};

const GridLayout = ({
  row,
  column,
  gridItemArray,
  headerText,
  containerStyle,
  GridItem,
  slugId,
  locationIs
}) => {
  const gridItem2dArray = gridConstructor(row, column, gridItemArray);
  return (
    <View style={styles.cardContainer}>
      <View style={[styles.flexRow, styles.headerContainer]}>
        <HTag tag="h2">
          <SText size={18} lineHeight={24} weight="500">
            {headerText}
          </SText>
        </HTag>
      </View>
      <View style={[styles.container, containerStyle]}>
        <View style={styles.shadowBox}>
          {gridItem2dArray.map((gridItem1dArray, rowIndex) => (
            <View
              key={`grid-item-2d-array-${generateUniqueKey()}`}
              style={[styles.flexRow, { justifyContent: "space-between" }]}
            >
              {gridItem1dArray.map((item, columnIndex) => (
                <GridItem
                  key={`grid-item-1d-array-${generateUniqueKey()}`}
                  slugId={slugId}
                  item={item}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  locationIs={locationIs}
                />
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    paddingVertical: 24,
    backgroundColor: "#fff"
  },
  headerContainer: {
    paddingHorizontal: 12
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center"
  },
  container: {
    marginHorizontal: 12,
    marginTop: 12,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#d6f1f7"
  },
  shadowBox: {
    borderColor: "#e4e6eb",
    borderRadius: 4,
    boxShadow: "0 0 4px 0 #e4e6eb"
  },
  headerText: {
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0,
    fontWeight: "600",
    color: "#030f09"
  }
});
export default GridLayout;
