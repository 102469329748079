import requestLocationPermission from "utils/location/requestLocationPermission";
import { mLog, mWarn } from "utils/logger";
import { Platform } from "react-native";
import { storeDataToAsyncStorage } from "screens/common/utils";
import GeoLocationDetect from "utils/location/geolocation";

// Please read this github discussion before editing location related functionality
// https://github.com/facebook/react-native/issues/7495

const cacheAgeInMilliseconds = 4 * 60 * 60 * 10 * 1000;
const locationConfigs = {
  enableHighAccuracy: false,
  timeout: 15000,
  // cache age in milliseconds
  maximumAge: cacheAgeInMilliseconds
};

export const authorized = "geo_location_authorized";

/**
 * @param {callback} success
 * => The action to be taken if lat long coordinates are received successfully
 * @param {callback} failure
 * => The action to be taken if any error occurs, eg. Permission denied
 * or fetch LatLong Coordinates failed
 * @param {boolean} useFallback
 * => The boolean flag to indicate if we should try getCurrentLocation as
 * a fallback attempt
 * @param {object} configs
 * => The location config used by the navigator for geolocation
 */
export default async function getLatLongPosition(
  success,
  failure,
  useFallback = true,
  configs = locationConfigs,
  permissionCallback
) {
  if ((navigator && navigator.geolocation) || Platform.OS === "android") {
    mLog("Navigator capability present. Proceed");
  } else {
    mLog("Navigator capability absent. Abort");
    return;
  }
  const granted = await requestLocationPermission();
  if (granted) {
    mLog("Permission granted!");
    cache(authorized, "true");
    await applyCallback(
      success,
      failure,
      useFallback,
      configs,
      permissionCallback
    );
  } else {
    cache(authorized, "false");
    mWarn("Permission denied!");
    if (failure) {
      const error = new Error("Permission was denied!");
      error.code = 1; // Error code for PERMISSION_DENIED
      await failure(error);
    }
  }
}

async function applyCallback(
  success,
  failure = (err) => {
    console.warn(err);
  },
  useFallback = true,
  configs = locationConfigs,
  permissionCallback
) {
  try {
    GeoLocationDetect().getCurrentPosition(
      async (position) => {
        if (permissionCallback) {
          permissionCallback(true);
        }
        try {
          mLog(position);
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          logLatitudeLongitudeData(latitude, longitude);
          await success(position);
        } catch (error) {
          handleLocationGenericError(error, failure);
        }
      },
      (error) => {
        if (permissionCallback) {
          permissionCallback(false);
        }
        handleLocationGenericError(error, failure);
      },
      configs
    );
  } catch (error) {
    console.warn(error);
  }
}

async function logLatitudeLongitudeData(latitude, longitude) {
  if (latitude && longitude) {
    cache("latitude", latitude.toString());
    cache("longitude", longitude.toString());
  }
}

function handleLocationGenericError(error, failure) {
  mLog("Error: ", error);
  console.warn(error);
  try {
    failure(error);
  } catch (error) {
    console.warn(error);
  }
}

async function cache(key, value) {
  if (key && value) {
    // No-op for valid inputs
  } else {
    mLog("Invalid input, exiting cache attempt.");
    return;
  }
  if (Platform.OS === "web") {
    // No-op for web, as of now
    // TODO :: Implement web caching logic here
  } else {
    try {
      await storeDataToAsyncStorage(key, value);
    } catch (error) {
      console.warn(error);
    }
  }
}

/**
 * Original Code (for reference)
 */

// --------------------------------------------------------------------------
// if (Platform.OS === "web") {
//   navigator.geolocation.getCurrentPosition(
//     position => {
//       const latitude = position.coords.latitude;
//       const longitude = position.coords.longitude;
//       this.getLocationFromLatLong(latitude, longitude);
//     },
//     error => this.setState({ error: error.message }),
//     { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
//   );
// } else {
//   try {
//     // According to answer at:
//     // https://stackoverflow.com/a/43188042/7768690
//     await this.requestLocationPermission();

//     this.watchID = navigator.geolocation.watchPosition(
//       position => {
//         // Create the object to update this.state.mapRegion through the onRegionChange function
//         const region = {
//           latitude: position.coords.latitude,
//           longitude: position.coords.longitude,
//           latitudeDelta: 0.00922 * 1.5,
//           longitudeDelta: 0.00421 * 1.5
//         };
//         this.getLocationFromLatLong(region.latitude, region.longitude);
//         navigator.geolocation.clearWatch(this.watchID);
//       },

//       error => {
//         console.warn(error);
//       }
//     );
//   } catch (err) {
//     console.warn(err);
//   }
// }
// --------------------------------------------------------------------------
