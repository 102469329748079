/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import Head from "next/head";
// import formatDate from "utils/formatDate";

const SEOHelper = () => {
  // const formattedDate = formatDate("MMMM, YYYY");
  const title = `Job Search in India | Blue Collar Jobs | Job Vacancies 2022-23 Rocket`;
  const description = `Search Latest Job Vacancies across India and Schedule an Interview in a go with Rocket. Search Jobs in top companies for Jobs like Sales, Delivery Boy, Customer Care, Data Entry etc. also get Sarkari Job updates with us.`;

  const Organization = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Rocket",
    legalName: "Rocket Pvt. Ltd.",
    url: "https://www.myrocket.co/",
    logo:
      "https://olxjobs-static.aasaanjobs.net/candidate_web/RocketIntegration/Rocket.svg",
    foundingDate: "2013",
    sameAs: [
      "https://www.facebook.com/RocketAppBP/",
      "https://twitter.com/RocketAppBP",
      "https://www.linkedin.com/company/rocket-app/",
      "https://www.youtube.com/c/RocketAppBP"
    ],
    numberOfEmployees: {
      "@type": "QuantitativeValue",
      value: "500+"
    },
    contactPoint: {
      "@type": "ContactPoint",
      email: "jobs.help@myrocket.co",
      contactType: "customer support"
    }
  };

  const WebSite = {
    "@context": "http://www.schema.org",
    "@type": "WebSite",
    name: "Rocket",
    url: "https://www.myrocket.co/"
  };

  return (
    <Fragment>
      <Head>
        <title key="title">{title}</title>
        <meta key="description" name="description" content={description} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(Organization)
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(WebSite)
          }}
        />
      </Head>
    </Fragment>
  );
};

export default SEOHelper;
