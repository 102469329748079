/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { View, StyleSheet, FlatList } from "react-native";
import SText from "styled42/components/SText";
import {
  deepGet,
  isWalkInJob,
  getCleverTapTags
} from "screens/common/utils/common";
import NavLink from "utils/routing/NavLink";
import JobCard from "screens/common/JobCard";
import SModal from "styled42/components/SModal";
import SSnackbar from "styled42/components/SSnackbar";
import EmptyState from "screens/JobList/components/EmptyState";
import { pushClevertapEvent } from "utils/clevertapEvents";
import {
  getLocationClevertapProps,
  locationFormatting,
  retrieveDataFromAsyncStorage
} from "screens/common/utils";
import SButton from "styled42/components/SButton";
import { slugify } from "utils/parseUrl";
import { getQueryFilter } from "screens/Dashboard/components/ListView/utils";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      snackbarVisible: false,
      snackbarBackgroundColor: "error",
      snackbarText: "",
      modalVisible: false,
      modalContent: null,
      modalData: {}, // For storing modal specific data, e.g. callbacks
      modalName: "",
      callHrVariant: "A",
      jobCardVariant: ""
    };
    this.firstLoad = true;
  }

  async componentDidMount() {
    const callHrVariant = await retrieveDataFromAsyncStorage("callHrVariant");
    const jobCardVariant = await retrieveDataFromAsyncStorage("jobCardVariant");
    this.setState({
      jobCardVariant,
      callHrVariant
    });
  }

  handleClevertapEvent = async ({
    edges = {},
    totalCount,
    featuredEdges = {},
    jobSource,
  }) => {
    const { keywordArray, faIds } = this.props;
    const { locationIs, user, query } = this.props;
    const firstTenJobId = [];
    const firstThreeCompanyTitle = [];
    const firstThreeJobTitle = [];
    const firstThreeFATitle = [];
    const firstFiveJobPricingPlanType = [];
    let paidJobCount = 0;

    edges.forEach((job, index) => {
      if (index < 3) {
        firstThreeJobTitle.push(job.title);
        firstThreeCompanyTitle.push(job.organization.name);
        firstThreeFATitle.push(job.functional_area.name);
      }
      if (index < 10) {
        firstTenJobId.push(job.id);
      }
      // for first five job store pricing plan of that job
      if (index < 5) {
        firstFiveJobPricingPlanType.push(job.pricing_plan_type);
      }
      // if pricing plan of job is not free and jobPosting, job is paid
      if (
        job &&
        job.pricing_plan_type &&
        job.pricing_plan_type !== "FREE" &&
        job.pricing_plan_type !== "JOB_POSTING"
      ) {
        paidJobCount += 1;
      }
    });

    if ((query && query.reloadClevertap) || this.firstLoad) {
      const locationProps = await getLocationClevertapProps(
        locationIs?.locality,
        user
      );

      pushClevertapEvent("Page Load", {
        Page_Modal: "Home",
        Current_Page: "home",
        Keywords: keywordArray.toString(),
        Location: locationIs ? locationIs.locality : "",
        "Number of results": totalCount,
        JobIds: firstTenJobId.toString(),
        "First Three Company": firstThreeCompanyTitle.toString(),
        "First Three Job Title": firstThreeJobTitle.toString(),
        "First Three FA": firstThreeFATitle.toString(),
        "User ID": user ? user.id : "NA",
        "Number of Paid job Cards": paidJobCount,
        "Pricing Plan Job Card 1": firstFiveJobPricingPlanType[0] || "NULL",
        "Pricing Plan Job Card 2": firstFiveJobPricingPlanType[1] || "NULL",
        "Pricing Plan Job Card 3": firstFiveJobPricingPlanType[2] || "NULL",
        "Pricing Plan Job Card 4": firstFiveJobPricingPlanType[3] || "NULL",
        "Pricing Plan Job Card 5": firstFiveJobPricingPlanType[4] || "NULL",
        FA_Count: faIds?.length || keywordArray.length,
        "Job Source": jobSource,
        ...locationProps
      });

      this.firstLoad = false;
    }
  };

  createJobCard = (item, index) => {
    const {
      pathname,
      location,
      query,
      user,
      sources,
      authToken,
      client,
      locationIs,
      keywordArray
    } = this.props;
    const { callHrVariant, jobCardVariant } = this.state;

    const jobGet = deepGet(item);

    const jobId = jobGet("id");
    const jobSlug = jobGet("slug");
    const hiringOrganisationName = jobGet("hiring_org_name");
    const organization = jobGet("organization", {});
    const functionalArea = jobGet("functional_area", {});
    const clientApprovalRequired = jobGet("client_approval_required");
    const isScreeningRequired = jobGet("is_screening_required");
    const isResumeRequired = jobGet("is_resume_subscribed");
    const pricingPlanType = jobGet("pricing_plan_type");
    const recentSlot = jobGet("recent_slot");
    const isWalkIn = isWalkInJob(item);
    const isFeatured = jobGet("is_featured");
    const jobDesignation = jobGet("designations.0");
    const organizationName =
      hiringOrganisationName || organization.popular_name || organization.name;

    const clevertapTags = getCleverTapTags(item);

    let jobTitle = jobGet("title");
    if (
      jobDesignation &&
      (pricingPlanType === "PREPAID" ||
        pricingPlanType === "POSTPAID" ||
        pricingPlanType === "TEMP_STAFFING")
    ) {
      jobTitle = jobDesignation;
    }

    const clevertapProps = {
      Type: "Apply",
      Page_Modal: "Home",
      Current_Page: "home",
      "Job Type": clientApprovalRequired ? "CAR" : "NCAR",
      "Job Pricing Plan": isScreeningRequired,
      "Job FA": functionalArea.name,
      "Job ID": jobId,
      "Card Number": index + 1,
      Location: locationIs?.locality,
      Slots: recentSlot !== null ? "Yes" : "No",
      Keywords: keywordArray.toString(),
      Tags: clevertapTags.length > 0 ? clevertapTags.join(", ") : "NA"
    };

    const queryVariables = this.getQueryVariables();

    const queryParams = {
      slug: `${jobSlug}-in-${organization?.slug}`,
      id: item?.id
    };

    return (
      <NavLink
        key={jobId}
        prefetch={false}
        route="jobDetail"
        params={queryParams}
        onClick={() => {
          pushClevertapEvent("Homepage Clicks", {
            Current_Page: "home",
            Type: "JobCard",
            "Test case": `JC_${jobCardVariant}`
          });
          pushClevertapEvent("Job Clicks", {
            Type: "JobCard",
            Page_Modal: "Home",
            Current_Page: "home",
            "Job Type": clientApprovalRequired ? "CAR" : "NCAR",
            "Job Pricing Plan": isScreeningRequired,
            "Job FA": functionalArea.name,
            "Job ID": jobId,
            "Card Number": index + 1,
            Location: locationIs?.locality,
            Slots: recentSlot !== null ? "Yes" : "No",
            Keywords: keywordArray.toString(),
            Tags: clevertapTags.length > 0 ? clevertapTags.join(", ") : "NA",
            "Test case": `JC_${jobCardVariant}`
          });
        }}
      >
        <JobCard
          index={index}
          callHrVariant={callHrVariant}
          queryVariables={queryVariables}
          pathname={pathname}
          location={location}
          jobId={jobId}
          jobSlug={jobSlug}
          title={jobTitle}
          pricingPlanType={pricingPlanType}
          clientApprovalRequired={clientApprovalRequired}
          isScreeningRequired={isScreeningRequired}
          isResumeRequired={isResumeRequired}
          functionalArea={functionalArea}
          tags={jobGet("tags")}
          isActive={jobGet("is_active")}
          places={jobGet("places")}
          locations={jobGet("locations")}
          experience={jobGet("expectation.work_exp_requirements")}
          offer={jobGet("offer")}
          applicationMetrics={jobGet("application_metrics")}
          isWalkInJob={isWalkIn}
          recentSlot={recentSlot}
          clevertapProps={clevertapProps}
          query={query}
          user={user}
          sources={sources}
          authToken={authToken}
          client={client}
          isFeatured={isFeatured}
          showSnackbar={this.showSnackbar}
          setModalOpen={this.setModalOpen}
          setModalClose={this.setModalClose}
          jobGet={jobGet}
          jobCardVariant={jobCardVariant}
          organizationName={organizationName}
        />
      </NavLink>
    );
  };

  getQueryVariables = () => {
    const {
      locationIs,
      jobsOnFirstFetch,
      faIds,
      gender,
      quickFilters,
      keywordArray
    } = this.props;

    const filter = getQueryFilter({ gender, faIds, quickFilters });

    const locationForQuery = locationFormatting(locationIs);

    return {
      location: locationForQuery,
      after: 0,
      first: jobsOnFirstFetch,
      filter,
      searchKeyword: faIds?.length ? null : keywordArray.join(",") // Search should be made using faIds in the filter if the faIds are available, otherwise it should be made using keyword
    };
  };

  loggedOutHomepageFooter = () => {
    const { locationIs, keywordArray } = this.props;
    const queryParams = {
      slug: slugify(`${keywordArray.join("-")}-jobs-in-${locationIs.name}`)
    };
    return (
      <NavLink prefetch={false} route="jobList" params={queryParams}>
        <View style={styles.showMoreButtonContainer}>
          <SButton
            text="Show more jobs"
            type="secondary"
            containerStyle={{ border: "none", backgroundColor: "#E8EAF4" }}
          />
        </View>
      </NavLink>
    );
  };

  getFooterComponent = (edges = [], totalCount = 0, fetchMore) => {
    const { referrer } = this.props;
    const remainingCount = totalCount - edges.length;

    // if (remainingCount < 1 || !fetchMore) {
    //   return null;
    // }

    return referrer === "Homepage" ? this.loggedOutHomepageFooter() : null;
  };

  hideSnackbar = () => {
    if (this.mounted) {
      this.setState({
        snackbarVisible: false
      });
    }
  };

  showSnackbar = (text, backgroundColor = "error") => {
    this.setState({
      snackbarVisible: true,
      snackbarBackgroundColor: backgroundColor,
      snackbarText: text
    });
  };

  // This performs diffrent operations on when modal is closed based on modal name
  handleModalClose = () => {
    const { modalName } = this.state;
    switch (modalName) {
      case "PREFERRED-LOCATION":
        // Don't do anything -> Don't want to close modal when backdrop clicked
        break;
      default:
        this.setModalClose();
    }
  };

  setModalClose = () => {
    if (this.mounted) {
      this.setState({
        modalVisible: false
      });
    }
  };

  setModalOpen = ({ modalName, modalContent, modalData }) => {
    this.setState({
      modalVisible: true,
      modalName,
      modalContent,
      modalData
    });
  };

  render() {
    const { selectedFA, referrer, data } = this.props;
    const {
      snackbarVisible,
      snackbarBackgroundColor,
      snackbarText,
      modalVisible,
      modalContent,
      loading: loadingMoreJobs
    } = this.state;

    const {
      searchJobsV2: { edges, totalCount, jobSource},
      featuredJobs: { edges: featuredJobs }
    } = data;
    const featuredJobsCount = featuredJobs?.length || 0;
    const totalEdges = [...featuredJobs, ...edges];
    this.handleClevertapEvent({ edges, totalCount, featuredJobs, jobSource });

    const emptyStateComponent = referrer === "Homepage" ? null : <EmptyState />;

    return (
      <>
        {totalCount > 0 ? (
          <View>
            <View style={[styles.flexStyles, styles.headerContainer]}>
              <SText size={12} color="#868594">
                <SText size={12} color="#13101d" weight="500">
                  {totalCount}
                </SText>{" "}
                {selectedFA && selectedFA.id !== "All"
                  ? selectedFA?.name || ""
                  : "popular"}{" "}
                jobs for you
              </SText>
            </View>
            <View style={styles.listContainer}>
              <FlatList
                data={totalEdges}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item, index }) => {
                  return (
                    <View
                      style={[
                        styles.cardContainer,
                        index === totalEdges.length - 1
                          ? styles.cardContainer__noBorder
                          : null
                      ]}
                    >
                      {this.createJobCard(item, index)}
                    </View>
                  );
                }}
                ListFooterComponent={this.getFooterComponent(edges, totalCount)}
              />
            </View>
          </View>
        ) : (
          emptyStateComponent
        )}
        <SSnackbar
          visible={snackbarVisible}
          text={snackbarText}
          backgroundColor={snackbarBackgroundColor}
          onCompletion={this.hideSnackbar}
        />
        <SModal visible={modalVisible} onCancel={this.handleModalClose}>
          {modalContent}
        </SModal>
      </>
    );
  }
}

ListView.propTypes = {
  locationIs: PropTypes.object.isRequired,
  faIds: PropTypes.array,
  gender: PropTypes.string,
  quickFilters: PropTypes.object,
  showSortOption: PropTypes.bool,
  keywordArray: PropTypes.array.isRequired,
  jobsOnFirstFetch: PropTypes.number,
  referrer: PropTypes.string.isRequired
};

ListView.defaultProps = {
  gender: null,
  showSortOption: true,
  jobsOnFirstFetch: 40,
  faIds: [],
  quickFilters: {}
};

const styles = StyleSheet.create({
  flexStyles: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  headerContainer: {
    backgroundColor: "#f6f6f6",
    paddingHorizontal: 16,
    paddingVertical: 12,
    justifyContent: "space-between"
  },
  listContainer: {
    backgroundColor: "#fff"
  },
  cardContainer: {
    borderBottomColor: "#e5e5e7",
    borderBottomWidth: 1
  },
  cardContainer__noBorder: {
    borderBottomWidth: 0
  },
  loaderOrErrorContainer: {
    margin: 25,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  modalStyle: {
    position: "absolute",
    left: -10,
    right: -10,
    bottom: 0
  },
  modalContainer: {
    padding: 16
  },
  modalHeader: {
    justifyContent: "space-between",
    marginBottom: 18
  },
  sortByItem: {
    marginBottom: 18,
    marginLeft: 4,
    alignSelf: "flex-start"
  },
  sortByLabel: {
    fontSize: 14,
    lineHeight: 20,
    color: "#676574"
  },
  sortByLabel_active: {
    fontWeight: "600",
    color: "#13101d"
  },
  viewMoreContainer: {
    minHeight: 70,
    paddingBottom: 30,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  viewMoreButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  showMoreButtonContainer: {
    paddingHorizontal: 16,
    marginTop: 20
  }
});

export default ListView;
