import gql from "graphql-tag";
import { mWarn } from "utils/logger";
import objectPath from "object-path";
import getLatLongPosition from "utils/location/getLatLongCoordinates";
import { graphQLQueryHelper, graphQLClientMutationHelper } from "utils/graphQL";
import { EDIT_CANDIDATE } from "screens/LoginRegistration/queries";
import getData from "utils/getData";
import { SetLocation } from "screens/common/LocationContext";

export const getCurrentLocation = (callback, apolloClient) => {
  getLatLongPosition(
    position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      getLocationFromLatLong(apolloClient, latitude, longitude, callback);
    },
    error => {
      if (error.code === 1) {
        callback(error);
      }
    }
  );
};


export const getLocationFromLatLong = async (
  apolloClient,
  latitude,
  longitude,
  callback
) => {
  const { data } = await graphQLQueryHelper(apolloClient)({
    query: SEARCH_LOCALITY,
    variables: {
      queryType: "LOCATION",
      Latitude: latitude,
      Longitude: longitude
    }
  });
  if (callback) {
    callback({ ...data, latitude, longitude });
  } else {
    return data.searchLocality.splice(0, 5);
  }
};

export const SEARCH_LOCALITY = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
    $cityId: Int
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
      city_id: $cityId
    ) {
      id
      name
      latitude
      longitude
      city {
        id
        name
      }
    }
  }
`;
