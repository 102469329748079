import config from "config";
import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import FABlock from "screens/common/FABlock";
import { LocationContext } from "screens/common/LocationContext";
import { FETCH_ALL_FUNCTIONAL_AREAS } from "screens/Dashboard/queries";
import HTag from "styled42/components/HTag";
import SButton from "styled42/components/SButton";
import SLoader from "styled42/components/SLoader";
import SText from "styled42/components/SText";
import { ChevronRight } from "styled42/icons";
import { pushClevertapEvent } from "utils/clevertapEvents";
import { graphQLQueryHelper } from "utils/graphQL";
import { slugify } from "utils/parseUrl";
import { getRecentSearchFAs } from "utils/recentSearch";
import NavLink from "utils/routing/NavLink";
import { navigate } from "utils/routing/NavRouter";
import VernacManager from "utils/vernacular";
import { GetPopularJobCategories, FA_ID_LIST } from "../utils";

class JobCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recentlySearchedFA: null
    };
  }

  componentDidMount = async () => {
    const { client } = this.props;
    const response = await graphQLQueryHelper(client)({
      query: FETCH_ALL_FUNCTIONAL_AREAS,
      variables: { ids: FA_ID_LIST }
    });
    const faData =
      response && response.data && response.data.fetchAllFunctionalAreas;
    const recentSearchFAs = await getRecentSearchFAs();
    this.setState({
      faData,
      recentlySearchedFA: recentSearchFAs[0]
    });
  };

  render() {
    const { faData } = this.state;
    console.log("FA Data from state: ", faData);
    const { onToggleSelectCity } = this.props;
    return (
      <View style={styles.container}>
        <LocationContext.Consumer>
          {({ locationIs }) => {
            const locationText = locationIs?.name?.split(",")[0];
            const location = slugify(locationText);
            return (
              <View style={styles.jobCategoryContainer}>
                <View style={styles.element}>
                  <View style={styles.headerTextContainer}>
                    <HTag
                      tag="h2"
                      style={{ margin: 0, lineHeight: 0, marginRight: 5 }}
                    >
                      <SText size={16} weight="bold">
                        {VernacManager.translateString(
                          "loggedout_popular_categories"
                        )}
                      </SText>
                    </HTag>
                  </View>
                </View>
                {faData && faData?.length ? (
                  <FABlock
                    FAlist={GetPopularJobCategories(location, faData)}
                    locationIs={location}
                    isDashboard={false}
                    onToggleSelectCity={onToggleSelectCity}
                  />
                ) : (
                  <SLoader />
                )}
                <NavLink
                  route="functionalArea"
                  prefetch={false}
                  onClick={() => {
                    pushClevertapEvent("Homepage Clicks", {
                      Type: "View All",
                      Section: "Jobs by Category"
                    });
                  }}
                >
                  <View
                    nativeID="seeAllJobCategoriesButton"
                    style={styles.showAll}
                  >
                    <SButton
                      onClick={() => {
                        navigate("functionalArea");
                      }}
                      text={VernacManager.translateString(
                        "loggedout_showmore_categories"
                      )}
                      type="text"
                      textProps={{
                        containerStyle: {
                          fontSize: 14,
                          color: "#1B2D93",
                          fontWeight: "bold",
                          paddingVertical: 12,
                          //border: "none",
                          backgroundColor: "#E8EAF4"
                        }
                      }}
                    />
                  </View>
                </NavLink>
                <View style={styles.employerLogin}>
                  <SText size={12}>
                    {VernacManager.translateString("loggedout_hire_candidates")}
                  </SText>
                  <NavLink
                    href={config.EMPLOYER_DESKTOP_WEB_BASE_URL}
                    rel="nofollow"
                    prefetch={false}
                    target="_blank"
                    onClick={() => {
                      pushClevertapEvent("Homepage Clicks", {
                        Type: "Employer login",
                        Section: "Jobs by Category"
                      });
                    }}
                  >
                    <View style={styles.linkContainer}>
                      <SText size={14} style={styles.employerLoginLink}>
                        {VernacManager.translateString(
                          "loggedout_employer_login"
                        )}
                      </SText>
                      <ChevronRight
                        width={16}
                        height={16}
                        fill="#FF9518"
                        style={{ marginLeft: 5, fontWeight: "bold" }}
                      />
                    </View>
                  </NavLink>
                </View>
              </View>
            );
          }}
        </LocationContext.Consumer>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#F7F8F9"
  },
  jobCategoryContainer: {
    marginTop: 14,
    paddingBottom: 10,
    ...Platform.select({
      web: {
        margin: "auto",
        width: "calc(100% - 24px)",
        boxShadow: "0 0 4px 0 #e4e6eb"
      },
      android: {
        margin: 12,
        width: "100%"
      }
    }),
    borderWidth: 1.5,
    borderColor: "#e4e6eb",
    borderRadius: 4,
    backgroundColor: "#FFFFFF"
  },
  element: {
    paddingTop: 16,
    paddingBottom: 20,
    paddingHorizontal: 16
  },
  showAll: {
    borderRadius: 8,
    borderWidth: 0,
    margin: 16,
    backgroundColor: "#E8EAF4"
  },
  employerLogin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FFF3E8",
    borderRadius: 8,
    margin: 16,
    padding: 12
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  employerLoginLink: {
    fontWeight: "bold",
    color: "#FF9518"
  },
  headerTextContainer: {
    flexDirection: "row",
    alignItems: "center"
  }
});

export default JobCategory;
