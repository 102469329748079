import gql from "graphql-tag";
import { queryFields } from "../Application/utils/query";

export const RECOMMEND_JOBS_FOR_NEW_CANDIDATE = gql`
  mutation recommendJobsForNewCandidate($payload: RecommendJobsInput!) {
    recommendJobsForNewCandidate(payload: $payload) {
      __typename
      totalCount
      edges {
        __typename
        id
        score
        explain
        title
        slug
        organization {
          __typename
          id
          name
          popular_name
          slug
          is_hr_agency
        }
        offer {
          __typename
          max_offered_salary
          min_offered_salary
          salary_format
        }
        tags
        application_metrics {
          __typename
          joinings
          selections
          previous_interviews
          upcoming_interviews
        }
        expectation {
          __typename
          work_exp_requirements {
            __typename
            max_experience
            min_experience
          }
        }
        pricing_plan_type
        client_approval_required
        is_screening_required
        recent_slot
        places {
          place_id
          short_formatted_address
          location
        }
        locations {
          place {
            place_id
            short_formatted_address
            location
            city
            locality
          }
        }
        functional_area {
          name
          id
        }
      }
    }
  }
`;

export const MATCHING_JOBS = gql`
  query findRecommendedJobs(
    $candidate_id: ID!
    $first: Int
    $after: Int
    $pageCursor: String
  ) {
    findRecommendedJobs(
      candidateId: $candidate_id
      first: $first
      after: $after
      pageCursor: $pageCursor
    ) {
      edges {
        id
        slug
        title
        designations
        client_approval_required
        is_screening_required
        recent_slot
        approved_on
        organization {
          id
          name
          popular_name
          slug
        }
        tags
        offer {
          salary_format
          min_offered_salary
          max_offered_salary
        }
        places {
          place_id
          short_formatted_address
        }
        expectation {
          work_exp_requirements {
            is_experience_mandatory
            min_experience
            max_experience
          }
        }
      }
    }
  }
`;

export const GET_PREQUALIFIED_CANDIDIATE_SCORE = gql`
  query getPrequalifiedCandidateScore($candidate_id: ID!) {
    getPrequalifiedCandidateScore(candidate_id: $candidate_id)
  }
`;

const ACTIVE_APPLICATION_FILTERS = `filter:{
  Application:{
    OR :[
      {
        stage: EMPLOYER_SHORTLISTED
      },
      {
        stage: TOBE_SCHEDULED_FOR_INTERVIEW
      },
      {
        stage: SCHEDULED_FOR_INTERVIEW
      },
      {
        stage: CANDIDATE_APPLIED
      }
    ],
    job: {state:OPEN}
  }
}`;

export const GET_APPLICATIONS = gql`
  query applications {
    nodes(type: Application,first: 1000, sort: "modified__DESC", ${ACTIVE_APPLICATION_FILTERS}) {
      totalCount
      pageInfo {
        totalPages
        pageNumber
        nextCursor
      }
      edges {
        ... on Application {
          ${queryFields}
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query userDetails {
    me {
      id
      first_name
      last_name
      mobile
      email
      email_verified
      mobile_verified
      referral_code
      stakeholder {
        ... on Candidate {
          id
        }
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
    ) {
      name
      longitude
      latitude
      city {
        name
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query companySearch($query: Text!) {
    topCompaniesByFA(query: $query) {
      popular_name
      slug
      logo
      job_count
    }
  }
`;

export const SCREENING_ANSWER_STATUS = gql`
  query ScreeningAnswerStatus($id: ID!) {
    screeningAnswerStatus(application_id: $id)
  }
`;

export const FETCH_FUNCTIONAL_AREAS = gql`
  query fetchFunctionalAreas($ids: [Int]!) {
    fetchFunctionalAreas(ids: $ids) {
      id
      name
      image
      full_image
    }
  }
`;

export const FETCH_ALL_FUNCTIONAL_AREAS = gql`
  query fetchAllFunctionalAreas($ids: [Int]!) {
    fetchAllFunctionalAreas(ids: $ids) {
      id
      name
      image
      full_image
      category_name
    }
  }
`;
