import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Animated,
  StyleSheet,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { pushClevertapEvent } from "utils/clevertapEvents";
import Colors from "../../config/colors";
import { ChevronDown } from "../../icons";
import SText from "../SText";

class SExpansionPanel extends Component {
  constructor(props) {
    super(props);
    const { expanded } = this.props;
    this.state = {
      expanded,
      animation: new Animated.Value(expanded ? 1 : 0)
    };
    this.maxHeight = 0;
  }

  componentDidUpdate() {
    const { expanded, animation } = this.state;
    Animated.timing(animation, {
      toValue: expanded ? 1 : 0,
      duration: 150
    }).start();
  }

  setMaxHeight(event) {
    if (this.maxHeight > 0) {
      return;
    }
    this.maxHeight = event.nativeEvent.layout.height;
  }

  toggle = () => {
    const { expanded } = this.state;
    const { type, clevertapProps } = this.props;
    if (type) {
      pushClevertapEvent("Job Clicks", {
        Type: expanded ? `Close ${type}` : `Open ${type}`,
        ...clevertapProps
      });
    }

    this.setState((prevState) => ({
      expanded: !prevState.expanded
    }));
  };

  render() {
    const {
      title,
      titleProps,
      iconProps,
      containerStyle,
      titleContainerStyle,
      bodyContainerStyle,
      children
    } = this.props;
    const { animation } = this.state;

    const height = animation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, this.maxHeight]
    });

    const rotate = animation.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "180deg"]
    });

    return (
      <View style={[styles.container, containerStyle]}>
        <TouchableWithoutFeedback onPress={this.toggle}>
          <View style={[styles.titleContainer, titleContainerStyle]}>
            <SText {...titleProps}>{title}</SText>
            <Animated.View
              style={{
                transform: [{ rotate }]
              }}
            >
              <ChevronDown height="24" width="24" {...iconProps} />
            </Animated.View>
          </View>
        </TouchableWithoutFeedback>
        <Animated.View
          style={{
            overflow: "hidden",
            height
          }}
        >
          <View
            onLayout={(event) => this.setMaxHeight(event)}
            style={[styles.bodyContainer, bodyContainerStyle]}
          >
            {children}
          </View>
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "relative"
  },
  titleContainer: {
    backgroundColor: Colors.Grey[3],
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 15
  },
  bodyContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16
  }
});

SExpansionPanel.defaultProps = {
  title: "",
  expanded: false
};

SExpansionPanel.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool
};

export default SExpansionPanel;
