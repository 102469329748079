import { View, StyleSheet, Platform } from "react-native";
import React from "react";
import SText from "styled42/components/SText";
import HTag from "styled42/components/HTag";
import NavLink from "utils/routing/NavLink";
import globalStyles from "styled42/config/globalStyles.config";
import { pushClevertapEvent } from "utils/clevertapEvents";
import colors from "styled42/config/colors";
import S3Image from "screens/common/S3Image";
import { slugify } from "utils/parseUrl";
import Colors from "styled42/config/colors";
import VernacManager from "utils/vernacular";

class JobsByExperience extends React.Component {
  constructor(props) {
    super(props);
    this.locationSelectMarker = false;
  }

  render() {
    const { locationIs } = this.props;
    const locationText = locationIs?.split(",")[0];

    return (
      <View style={[styles.container]}>
        <HTag tag="h2" style={{ margin: 0, lineHeight: 0 }}>
          <View style={styles.headerContainer}>
            <SText size={18} lineHeight={24} weight="500">
              {`${locationText} ${VernacManager.translateString(
                "loggedout_jobs_by_experience"
              )} `}
            </SText>
          </View>
        </HTag>
        <View style={styles.ExperienceContainer}>
          <View style={styles.ExperienceheaderContainer}>
            <NavLink
              route="jobList"
              params={{
                show_only_job: "fresher_job",
                slug: `jobs-in-${slugify(locationText)}`
              }}
              onClick={() => {
                pushClevertapEvent("Homepage clicks", {
                  Section: "Experience",
                  Type: "Freshers"
                });
              }}
            >
              <View style={styles.header}>
                <SText size={12} color="secondary">
                  {VernacManager.translateString("loggedout_jobs_for")}
                </SText>
                <SText size={14} color="primary" style={{ marginBottom: 16 }}>
                  {VernacManager.translateString("generic_freshers")}
                </SText>
                <SText size={12} color={Colors.Purple[1]} weight="600">
                  {VernacManager.translateString("loggedout_check_now")}
                </SText>
              </View>
              <S3Image
                path="OlxIntegration"
                name="freshers_img_homepage"
                style={styles.icon}
              />
            </NavLink>
          </View>
          <View style={styles.ExperienceheaderContainer}>
            <NavLink
              route="jobList"
              params={{
                slug: `jobs-in-${slugify(locationIs)}`,
                experience: "gt0"
              }}
              onClick={() => {
                pushClevertapEvent("Homepage clicks", {
                  Section: "Experience",
                  Type: "Experienced"
                });
              }}
            >
              <View style={styles.header}>
                <SText size={12} color="secondary">
                  {VernacManager.translateString("loggedout_jobs_for")}
                </SText>
                <SText size={14} color="primary" style={{ marginBottom: 16 }}>
                  {VernacManager.translateString("loggedout_experienced")}
                </SText>
                <SText size={12} color={Colors.Purple[1]} weight="600">
                  {VernacManager.translateString("loggedout_check_now")}
                </SText>
              </View>
              <S3Image
                path="OlxIntegration"
                name="experienced_img_homepage"
                style={styles.icon}
              />
            </NavLink>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: globalStyles.colors.Primary["000"],
    paddingTop: 20,
    paddingBottom: 26,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.Grey[2]
  },
  headerContainer: {
    paddingBottom: 15,
    paddingHorizontal: 16
  },
  ExperienceContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    ...Platform.select({
      web: {
        margin: "auto",
        width: "calc(100% - 34px)"
      },
      android: {
        margin: 17,
        width: "100%"
      }
    })
  },
  ExperienceheaderContainer: {
    width: "48%",
    borderWidth: 1.5,
    borderColor: "#e4e6eb",
    borderRadius: 4,
    boxShadow: "0 0 4px 0 #e4e6eb",
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 12
  },
  icon: {
    height: 63,
    width: 67,
    position: "absolute",
    bottom: 0,
    right: 0
  }
});

export default JobsByExperience;
