/* eslint-disable import/prefer-default-export */

import config from "config";
import { mLog } from "utils/logger";

export const fetchPlaceDetails = placeId => {
  const url = `${config.WEB_BASE_URL}/place/${placeId}/`;

  return fetch(`${url}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  })
    .then(res => res.json())
    .then(resJson => resJson)
    .catch(error => {
      mLog("error =========> ", error);
    });
};
