import React from "react";
import { View, Image, Dimensions, StyleSheet, Platform } from "react-native";
import IcSpeakerG from "icons/IcSpeakerG";
import IcKeyboardG from "icons/IcKeyboardG";
import SText from "styled42/components/SText";
import { pushClevertapEvent } from "utils/clevertapEvents";
import SClickableArea from "styled42/components/SClickableArea";
import { navigate } from "utils/routing/NavRouter";
import SModal from "styled42/components/SModal";
import { mLog } from "utils/logger";
import globalStyles from "styled42/config/globalStyles.config";
import { slugify } from "utils/parseUrl";

const voice = require("../assets/voiceGIF.gif");

export default class VoiceSearch extends React.Component {
  constructor(props) {
    super(props);
    const { FAname } = this.props;
    this.state = {
      showModal: true,
      timeout: false,
      EmptyShow: `Try speaking ${FAname}`
    };
  }

  componentDidMount() {
    this.handleVoiceInput();
  }

  componentWillUnmount() {
    this.setState({ showModal: false });
    this.recognizer.stop();
  }

  handleVoiceInput = () => {
    const { show, EmptyShow, startedTime } = this.state;
    window.SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition || null;
    if (window.SpeechRecognition === null) {
      mLog("sorry your browser doesnot support voice-input");
    } else {
      this.recognizer = new window.SpeechRecognition();
      this.recognizer.interimResults = true;
      this.recognizer.addEventListener("result", event => {
        let fullString = "";
        for (let i = event.resultIndex; i < event.results.length; i += 1) {
          if (event.results[i].isFinal) {
            this.setState({ show: event.results[i][0].transcript }, () =>
              mLog("in final", show)
            );
          } else {
            fullString += event.results[i][0].transcript;
            this.setState({ show: fullString }, () =>
              mLog("in else", show)
            );
          }
        }
      });

      this.recognizer.onend = () => {
        mLog("ended---", show);
        if (
          Boolean(show) &&
          show !== EmptyShow &&
          show !== ""
        ) {
          mLog("navigating -----");
          pushClevertapEvent("Search Clicks", {
            Type: "Search-Voice",
            keywords: show,
            Page_Modal: "Voice Search Sheet",
            Time_Taken: `${(new Date().getTime() - startedTime) /
              1000} sec`
          });
          let keyword = show.toLowerCase();
          keyword = keyword.split(" ").join("-");
          navigate("jobList", {
            slug: slugify(`${keyword}-jobs`)
          });
        } else {
          mLog("not navigating so time out");
          this.setState({ timeout: true });
        }
      };

      this.recognizer.onerror = event => {
        mLog(`Recognition error:${event.message}`);
      };
      try {
        this.recognizer.start();
        this.setState({
          show: EmptyShow,
          timeout: false,
          startedTime: new Date().getTime()
        });
        mLog("recognition started");
      } catch (ex) {
        mLog("some error occured", ex.message);
      }
    }
  };

  TextComponent = () => {
    const { show, EmptyShow, timeout } = this.state;
    if (timeout) {
      mLog("in timed out---", show, timeout);
      return (
        <View style={styles.keyboardContainer}>
          <SText type="text2" style={styles.emptyText}>
            Tap the mic button
          </SText>
          <View style={styles.micMain}>
            <SClickableArea
              onClick={() => {
                pushClevertapEvent("General Clicks", {
                  Type: "Search-Voice",
                  Page_Modal: "Voice Search Sheet",
                  Section: "Bottom Sheet"
                });
                this.handleVoiceInput();
              }}
            >
              <View style={styles.micContainer}>
                <View style={styles.micIcon}>
                  <IcSpeakerG
                    height={24}
                    width={24}
                    color={globalStyles.colors.paper.primary}
                  />
                </View>
              </View>
            </SClickableArea>
          </View>
        </View>
      );
    }
    mLog("in not timed out---", show, timeout);
    return (
      <View style={styles.keyboardContainer}>
        <SText
          type="text2"
          style={
            show === EmptyShow ? styles.textType1 : styles.textType2
          }
        >
          {show}
        </SText>
        <View style={styles.gifView}>
          <Image source={voice} style={styles.gifStyle} />
        </View>
      </View>
    );
  };

  finalComponent = () => (
    <View style={styles.keyboardContainer}>
      <View style={styles.keyboardContainer}>{this.TextComponent()}</View>
      <View style={styles.keyboardView}>
        <SClickableArea
          onClick={() => {
            this.props.handleKeyboardClick();
          }}
        >
          <IcKeyboardG height={24} width={24} />
        </SClickableArea>
      </View>
    </View>
  );

  render() {
    const { showModal } = this.state;
    return (
      <SModal
        visible={showModal}
        modalContainerStyle={styles.modalView}
        onCancel={() => {
          this.props.setFalse();
        }}
      >
        {this.finalComponent()}
      </SModal>
    );
  }
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    ...Platform.select({
      web: { position: "fixed" }
    }),
    borderRadius: 0,
    bottom: 0,
    minHeight: "30%",
    alignSelf: "center",
    width: Dimensions.get("window").width
  },
  keyboardView: { alignSelf: "flex-end", justifyContent: "flex-end" },
  keyboardContainer: { flex: 1 },
  gifView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    margin: 20
  },
  textType1: {
    color: globalStyles.colors.ink.lightest,
    flexWrap: "wrap",
    marginBottom: 5
  },
  textType2: {
    color: globalStyles.colors.ink.primary,
    marginBottom: 5
  },
  micIcon: {
    alignItems: "center",
    marginTop: 8
  },
  emptyText: {
    color: globalStyles.colors.ink.lightest,
    textAlign: "center"
  },
  micContainer: {
    backgroundColor: globalStyles.colors.blue.primary,
    height: 40,
    width: 40,
    borderRadius: 35,
    shadowColor: "#000000",
    shadowOpacity: 0.2,
    shadowRadius: 3,
    shadowOffset: { height: 4, width: 1 }
  },
  micMain: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20
  },
  gifStyle: {
    height: 50,
    width: 50
  }
});
