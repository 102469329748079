import React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import SText from "styled42/components/SText";
import { pushClevertapEvent } from "utils/clevertapEvents";
import colors from "styled42/config/colors";
import { listOfCompanies } from "screens/HomePage/utils";
import VernacManager from "utils/vernacular";

const TopCompanyJobs = () => (
  <View style={styles.container}>
    <View style={styles.headerContainer}>
      <SText style={styles.headerText}>
        {VernacManager.translateString("loggedout_trusted_by")}
      </SText>
      <SText style={styles.subHeader}>
        {VernacManager.translateString("loggedout_from_local_to_enterprises")}
      </SText>
    </View>
    <FlatList
      horizontal
      contentContainerStyle={styles.flatList}
      data={listOfCompanies}
      keyExtractor={(item, index) => `company-${index}`}
      renderItem={({
        item: {
          name,
          imageName,
          path = "OlxIntegration",
          imageType = "svg",
          styleName
        },
        index
      }) => (
        <View style={styles.item}>
          <S3Image
            type={imageType}
            path={path}
            name={imageName}
            style={styles[styleName]}
          />
        </View>
      )}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingBottom: 48,
    borderBottomWidth: 1,
    borderBottomColor: colors.Grey[2],
    backgroundColor: "#FFFFFF"
  },
  headerContainer: {
    margin: "auto"
  },
  headerText: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: "bold",
    paddingLeft: 28
  },
  subHeader: {
    marginTop: 8,
    paddingHorizontal: 28,
    color: "#868594",
    opacity: 0.7,
    fontSize: 12
  },
  flatList: {
    marginTop: 40,
    margin: "auto",
    alignItems: "center"
  },
  icon1: {
    height: 94,
    width: 95
  }
});

export default TopCompanyJobs;
