import React from "react";
import { StyleSheet, View } from "react-native";
import SText from "styled42/components/SText";
import { pushClevertapEvent } from "utils/clevertapEvents";
import NavLink from "utils/routing/NavLink";
import generateUniqueKey from "utils/generateUniqueKey";
import colors from "styled42/config/colors";
import VernacManager from "utils/vernacular";

const maxlimit = 16;

const Footer = (containerStyle) => {
  const footerData = [
    {
      title: VernacManager.translateString("footer_jobs_by_type"),
      section: "Popular Categories",
      links: [
        {
          name: VernacManager.translateString("Telesales / Telemarketing"),
          url: "telesales-telemarketing-jobs"
        },
        {
          name: VernacManager.translateString("Operations Executive"),
          url: "operations-executive-jobs"
        },
        {
          name: VernacManager.translateString("Back Office Executive"),
          url: "back-office-executive-jobs"
        },
        {
          name: VernacManager.translateString("Sales / Business Development"),
          url: "sales-business-development-jobs"
        },
        {
          name: VernacManager.translateString("Delivery Boy"),
          url: "delivery-boy-jobs"
        },
        {
          name: VernacManager.translateString("Customer Care"),
          url: "customer-care-jobs"
        },
        {
          name: VernacManager.translateString("Human Resource (HR)"),
          url: "human-resource-hr-jobs"
        },
        {
          name: VernacManager.translateString("Admin Executive"),
          url: "admin-executive-jobs"
        },
        {
          name: VernacManager.translateString("Accountant"),
          url: "accountant-jobs"
        },
        {
          name: VernacManager.translateString("Data Entry Operator"),
          url: "data-entry-operator-jobs"
        },
        {
          name: VernacManager.translateString("Driver"),
          url: "driver-jobs"
        },
        {
          name: VernacManager.translateString("Medical Representative"),
          url: "medical-representative-jobs"
        },
        {
          name: VernacManager.translateString("Nurse / Wardboy"),
          url: "nurse-wardboy-jobs"
        },
        {
          name: VernacManager.translateString("Receptionist/Front Desk"),
          url: "receptionist-jobs"
        },
        {
          name: VernacManager.translateString("Tutor / Teacher"),
          url: "tutor-teacher-jobs"
        },
        {
          name: VernacManager.translateString("Technician"),
          url: "other-technicians-jobs"
        },
        {
          name: VernacManager.translateString("generic_part_time"),
          url: "part-time-jobs"
        },
        {
          name: VernacManager.translateString("generic_wfh"),
          url: "work-from-home-jobs"
        }
      ]
    },
    {
      title: VernacManager.translateString("footer_jobs_by_city"),
      section: "Top Cities",
      links: [
        {
          name: VernacManager.translateString("location_page_new_delhi"),
          url: "jobs-in-delhi"
        },
        {
          name: VernacManager.translateString("location_page_mumbai"),
          url: "jobs-in-mumbai"
        },
        {
          name: VernacManager.translateString("location_page_kolkata"),
          url: "jobs-in-kolkata"
        },
        {
          name: VernacManager.translateString("location_page_bengaluru"),
          url: "jobs-in-bangalore"
        },
        {
          name: VernacManager.translateString("location_page_chennai"),
          url: "jobs-in-chennai"
        },
        {
          name: VernacManager.translateString("location_page_hyderabad"),
          url: "jobs-in-hyderabad"
        },
        {
          name: VernacManager.translateString("location_page_pune"),
          url: "jobs-in-pune"
        },
        {
          name: VernacManager.translateString("Noida"),
          url: "jobs-in-noida"
        },
        {
          name: VernacManager.translateString("location_page_ahmedabad"),
          url: "jobs-in-ahmedabad"
        },
        {
          name: VernacManager.translateString("location_page_gurgaon"),
          url: "jobs-in-gurgaon"
        },
        {
          name: VernacManager.translateString("Lucknow"),
          url: "jobs-in-lucknow"
        },
        {
          name: VernacManager.translateString("Kanpur"),
          url: "jobs-in-kanpur"
        },
        {
          name: VernacManager.translateString("Jaipur"),
          url: "jobs-in-jaipur"
        },
        {
          name: VernacManager.translateString("Indore"),
          url: "jobs-in-indore"
        },
        {
          name: VernacManager.translateString("Coimbatore"),
          url: "jobs-in-coimbatore"
        },
        {
          name: VernacManager.translateString("Ludhiana"),
          url: "jobs-in-ludhiana"
        },
        {
          name: VernacManager.translateString("Mysore"),
          url: "jobs-in-mysore"
        },
        {
          name: VernacManager.translateString("Patna"),
          url: "jobs-in-patna"
        }
      ]
      // viewAllProps: {
      //   route: "jobsByCity"
      // }
    },
    {
      title: VernacManager.translateString("footer_jobs_by_company"),
      section: "Top Companies",
      links: [
        {
          name: VernacManager.translateString("Amazon"),
          url: "amazon-jobs"
        },
        {
          name: VernacManager.translateString("Eureka Forbes..."),
          url: "eureka-forbes-ltd-jobs"
        },
        {
          name: VernacManager.translateString("Zomato Jobs"),
          url: "zomato-jobs"
        },
        {
          name: VernacManager.translateString("Udaan"),
          url: "udaan-jobs"
        },
        {
          name: VernacManager.translateString("Kiranakart"),
          url: "kiranakart-technolgies-pvt-ltd-jobs"
        },
        {
          name: VernacManager.translateString("Big Basket"),
          url: "big-basket-jobs"
        },
        {
          name: VernacManager.translateString("Swiggy Jobs"),
          url: "swiggy-jobs"
        },
        {
          name: VernacManager.translateString("Byjus"),
          url: "byjus-jobs"
        },
        {
          name: VernacManager.translateString("Practo"),
          url: "practo-jobs"
        }
      ]
      // viewAllProps: {
      //   route: "jobsByCompany"
      // }
    }
  ];
  return (
    <View style={[styles.container, containerStyle]}>
      {footerData.map(({ title, links, section, viewAllProps }) => (
        <View
          key={`section-${generateUniqueKey()}`}
          style={styles.sectionContainer}
        >
          <SText size={14} weight="bold" color="#676574">
            {title}
          </SText>
          <View style={styles.linkContainer}>
            {links.map(({ name, url }) => (
              <View key={`link-${generateUniqueKey()}`} style={styles.linkBox}>
                <NavLink
                  route="jobList"
                  params={{
                    slug: url
                  }}
                  onClick={() => {
                    pushClevertapEvent("Footer Clicks", {
                      Section: section,
                      Type: name
                    });
                  }}
                >
                  <SText
                    size={12}
                    ellipsizeMode="tail"
                    ellipsizeMode="head"
                    color="#676574"
                  >
                    {name.length > maxlimit
                      ? name.substring(0, maxlimit - 3) + "..."
                      : name}
                  </SText>
                </NavLink>
              </View>
            ))}
            {/* <View style={styles.linkBox}>
            <NavLink
              {...viewAllProps}
              onClick={() => {
                pushClevertapEvent("Footer Clicks", {
                  Section: title,
                  Type: "View all"
                });
              }}
            >
              <SText size={12} color={colors.Purple[1]} weight="500">
                View all
              </SText>
            </NavLink>
          </View> */}
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#F6F6F6",
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 10
    // borderTopWidth: 1,
    // borderTopColor: "#bbc0cc"
  },
  sectionContainer: {
    marginBottom: 24
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
  },
  linkBox: {
    width: "33.33%",
    paddingVertical: 2,
    marginBottom: 14
  }
});

export default Footer;
