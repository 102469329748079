import React from "react";
import { View, Platform, StyleSheet } from "react-native";
import { initPlacePrediction } from "screens/common/utils/PlaceAutoSuggest";
import SText from "styled42/components/SText";
import SClickableArea from "styled42/components/SClickableArea";
import { pushClevertapEvent } from "utils/clevertapEvents";
import { getLocationText, deepGet } from "./utils";
import { Colors } from "styled42/config";
import { fetchPlaceDetails } from "screens/common/utils/FetchPlaceDetail";

class CitySuggestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getPlacePredictions: initPlacePrediction(),
      suggestions: [],
      autoCompleteRequestCount: 0
    };
  }

  componentDidMount() {
    this.handleAutocomplete();
  }

  componentDidUpdate(prevProps) {
    const { searchText } = this.props;
    const { searchText: prevSearchTextIs } = prevProps;
    if (searchText !== prevSearchTextIs) {
      this.handleAutocomplete();
    }
  }

  handleAutocomplete = () => {
    const { searchText } = this.props;
    const { getPlacePredictions } = this.state;
    if (!searchText || searchText === "") return;
    if (Platform.OS === "web") {
      let searchOptions = {
        componentRestrictions: { country: "in" },
        types: ["(cities)"]
      };

      getPlacePredictions(searchText, searchOptions, (predictions, status) => {
        if (predictions && predictions.length > 0) {
          this.setState((prevState) => {
            return {
              autoCompleteRequestCount: prevState.autoCompleteRequestCount + 1,
              suggestions: predictions.map((p) => ({
                placeID: p.place_id,
                name: getLocationText(p.structured_formatting)
              }))
            };
          });
        }
      });
    } else {
      let searchOptions = {
        country: "in",
        types: "cities"
      };
      getPlacePredictions(searchText, searchOptions, (predictions, status) => {
        if (predictions && predictions.length > 0) {
          this.setState((prevState) => {
            return {
              autoCompleteRequestCount: prevState.autoCompleteRequestCount + 1,
              suggestions: predictions.map((item) => ({
                placeID: item.placeID,
                name: getLocationText({
                  main_text: item.primaryText,
                  secondary_text: item.secondaryText
                })
              }))
            };
          });
        }
      });
    }
  };

  render() {
    const { suggestions } = this.state;
    const { referrer } = this.props;
    if (suggestions.length === 0) {
      return (
        <View style={styles.loading}>
          <SText>Loading...</SText>
        </View>
      );
    }
    return (
      <View style={{ height: 280 }}>
        {suggestions.map((item, index) => (
          <SClickableArea
            key={index}
            onClick={async () => {
              pushClevertapEvent(
                referrer === "home"
                  ? "HomePage Clicks"
                  : "loginRegister Clicks",
                {
                  Current_Page: referrer || "New loginRegistration",
                  Page_Modal: "Location Select",
                  Type: "Location Selected - Search"
                }
              );
              const res = await fetchPlaceDetails(item.placeID);
              const getLocation = deepGet(res);
              const location = getLocation(
                Platform.OS === "web"
                  ? "results.0.geometry.location"
                  : "location"
              );
              this.props.setCity({
                cityName: item.name,
                location
              });
            }}
          >
            <SText
              containerStyle={styles.suggestionText}
              size={14}
              color={Colors.Grey[7]}
              key={index}
            >
              {item.name}
            </SText>
          </SClickableArea>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loading: {
    height: 280,
    justifyContent: "center",
    alignItems: "center"
  },
  suggestionText: {
    paddingVertical: 12,
    marginLeft: 8
  }
});

export default CitySuggestion;
