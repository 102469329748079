import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import globalStyles from "styled42/config/globalStyles.config";
import { pushClevertapEvent } from "utils/clevertapEvents";
import SText from "styled42/components/SText";
import S3Image from "screens/common/S3Image";
import config from "config";
import NavLink from "utils/routing/NavLink";
import generateUniqueKey from "utils/generateUniqueKey";
import Colors from "styled42/config/colors";
import VernacManager from "utils/vernacular";

const LookinToHire = () => {
  const benefits = [
    {
      img: "icon_home_ml_1",
      value: VernacManager.translateString("loggedout_ai_based_suggestions")
    },
    {
      img: "icon_home_sms_1",
      value: VernacManager.translateString("loggedout_email_blast")
    },
    {
      img: "icon_home_shortlist_1",
      value: VernacManager.translateString(
        "loggedout_highly_relevant_applications"
      )
    }
  ];
  return (
    <View style={styles.bg}>
      <View style={styles.bannerContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <SText size={16} weight="bold">
              {VernacManager.translateString("loggedout_looking_to_hire")}
            </SText>
            <SText size={14} style={{ color: "#868594", marginTop: 2 }}>
              {VernacManager.translateString("loggedout_suitable_nearyou")}
            </SText>
          </View>
          <S3Image
            path="RocketIntegration"
            name="post_job"
            style={{ height: 96, width: 80 }}
          />
        </View>
        <View style={styles.buttonContainer}>
          <NavLink
            href={`${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/register/`}
            rel="nofollow"
            prefetch={false}
            target="_blank"
            onClick={() => {
              pushClevertapEvent("Homepage Clicks", {
                Section: "Looking to Hire",
                Type: "Post Job"
              });
            }}
          >
            <View style={styles.postButton}>
              <SText size={14} style={{ color: Colors.Grey[7] }}>
                {VernacManager.translateString("loggedout_job_posting")}
              </SText>
            </View>
          </NavLink>
        </View>
        <View style={styles.benifitsBanner}>
          <View style={{ flex: 1, height: 1, backgroundColor: "#F2F3F4" }} />
          <View style={styles.banner}>
            <SText size={14} weight="bold">
              {VernacManager.translateString(
                "loggedout_benefits_for_employers"
              )}
            </SText>
          </View>
          <View style={{ flex: 1, height: 1, backgroundColor: "#c1eaf0" }} />
        </View>
        <View style={styles.benifitsContainer}>
          {benefits.map((item) => (
            <View
              key={`-benefits-item-array-${generateUniqueKey()}`}
              style={styles.benifitItem}
            >
              <S3Image
                path="RocketIntegration"
                name={item.img}
                style={{ height: 32, width: 32 }}
              />
              <SText style={{ color: "#868594", fontSize: 12, marginLeft: 16 }}>
                {item.value}
              </SText>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bg: {
    backgroundColor: "#F2F3F4",
    paddingTop: 10
  },
  bannerContainer: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    marginTop: 14,
    ...Platform.select({
      web: {
        margin: "auto",
        width: "calc(100% - 24px)",
        boxShadow: "0 0 4px 0 #e4e6eb"
      },
      android: {
        margin: 12,
        width: "100%"
      }
    }),

    borderWidth: 1.5,
    borderColor: "#e4e6eb",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    position: "relative"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row"
  },
  header: {
    flex: 1
  },
  text: {
    color: globalStyles.colors.Primary[1000],
    marginLeft: 4
  },
  buttonContainer: {
    position: "absolute",
    top: 100,
    width: "inherit",
    right: 12
  },
  postButton: {
    backgroundColor: Colors.Orange[5],
    paddingVertical: 12,
    borderRadius: 4,
    alignItems: "center",
    boxShadow: "0 4px 5px 0 #C0C0C0"
  },
  benifitsBanner: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 50,
    ...Platform.select({
      web: {
        margin: "auto",
        width: "calc(100% - 6px)"
      },
      android: {
        margin: 3,
        width: "100%"
      }
    })
  },
  banner: {
    backgroundColor: "#F2F3F4",
    margin: "auto",
    borderRadius: 15,
    alignItems: "center",
    paddingVertical: 6,
    paddingHorizontal: 30
  },
  benifitsContainer: {
    display: "flex",
    paddingHorizontal: 28,
    margin: "auto",
    marginTop: 22
  },
  benifitItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 20
  }
});

export default LookinToHire;
