import React from "react";
import { View, StyleSheet, FlatList, Platform, Fragment } from "react-native";
import SText from "styled42/components/SText";
import S3Image from "screens/common/S3Image";
import { pushClevertapEvent } from "utils/clevertapEvents";
import NavLink from "utils/routing/NavLink";
import globalStyles from "styled42/config/globalStyles.config";
import colors from "styled42/config/colors";
import { listOfPopularCity } from "screens/HomePage/utils";
import { slugify } from "utils/parseUrl";
import SClickableArea from "styled42/components/SClickableArea";
import VernacManager from "utils/vernacular";

const TopCityJobs = ({
  jobArray,
  locationIs,
  onToggleSelectCity,
  setCurrentLocation,
  referrer
}) => {
  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        extraData={[jobArray, locationIs]}
        contentContainerStyle={styles.flatList}
        data={listOfPopularCity}
        keyExtractor={(item, index) => `company-${index}`}
        renderItem={({
          item: {
            name,
            imageName,
            imageType = "svg",
            styleName,
            location,
            vernacKey
          },
          index
        }) => {
          const route = jobArray ? "jobList" : "functionalArea";
          const queryParams = jobArray
            ? // Navigate to joblist
              { slug: slugify(`${jobArray}-jobs-in-${name}`) }
            : // navigate to functionalAreas
              { location: slugify(name) };
          const renderCity = (
            <View
              style={[
                styles.item,
                index === 0 && styles.first,
                index === listOfPopularCity.length - 1 && styles.last
              ]}
            >
              {styleName === "" ? null : (
                <S3Image
                  type={imageType}
                  name={imageName}
                  style={styles[styleName]}
                  path="RocketIntegration"
                />
              )}
              <SText size={12} lineHeight={14} color="secondary">
                {VernacManager.translateString(vernacKey)}
              </SText>
            </View>
          );
          return (
            <>
              {styleName === "icon2" && (
                <SClickableArea
                  onClick={() => {
                    if (referrer === "jobList") {
                      onToggleSelectCity(true);
                      pushClevertapEvent("General clicks", {
                        Current_Page: referrer,
                        Section: "Select City - mid listing",
                        Type: "View more"
                      });
                    } else {
                      onToggleSelectCity(true, "jobList", {
                        slug: "jobs"
                      });
                      pushClevertapEvent("Homepage clicks", {
                        Section: "Top Cities",
                        Type: "View more"
                      });
                    }
                  }}
                >
                  <S3Image
                    path="OlxIntegration"
                    name="right_chevron"
                    style={styles.icon2}
                  />
                  <SText
                    size={12}
                    lineHeight={14}
                    color="secondary"
                    containerStyle={{ marginTop: 5 }}
                  >
                    {VernacManager.translateString("jd_view_all")}
                  </SText>
                </SClickableArea>
              )}
              {styleName !== "icon2" &&
                (referrer === "jobList" ? (
                  <SClickableArea
                    onClick={() => {
                      if (referrer === "jobList") {
                        onToggleSelectCity(false, name);
                      }
                      pushClevertapEvent("General Clicks", {
                        Current_Page: referrer,
                        Section: "Top Cities",
                        Location: name,
                        Type: "Select City - mid listing"
                      });
                    }}
                  >
                    {renderCity}
                  </SClickableArea>
                ) : (
                  <NavLink
                    route={route}
                    params={queryParams}
                    onClick={async () => {
                      pushClevertapEvent("Homepage clicks", {
                        Section: "Top Cities",
                        Type: name
                      });
                      await setCurrentLocation(
                        "current_location",
                        name,
                        location.lat,
                        location.long
                      );
                    }}
                  >
                    {renderCity}
                  </NavLink>
                ))}
            </>
          );
        }}
      />
    </View>
  );
};

class PopularCitiesJobs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      jobArray,
      locationIs,
      onToggleSelectCity,
      client,
      setCurrentLocation,
      referrer
    } = this.props;
    return (
      <View style={styles.bg}>
        <View style={styles.bannerContainer}>
          <View style={styles.headerContainer}>
            <View>
              <SText size={12} lineHeight={24} align="center" color="#13101d">
                {VernacManager.translateString("loggedout_popular_city_jobs")}
              </SText>
            </View>
          </View>
        </View>
        <TopCityJobs
          jobArray={jobArray[0]}
          locationIs={locationIs}
          onToggleSelectCity={onToggleSelectCity}
          setCurrentLocation={setCurrentLocation}
          client={client}
          referrer={referrer}
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  bg: {
    backgroundColor: "#f7f8f9"
  },
  bannerContainer: {
    paddingHorizontal: 16,
    backgroundColor: "#f7f8f9"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 9,
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10
  },
  container: {
    borderBottomWidth: 1,
    borderBottomColor: colors.Grey[2]
  },
  headerText: {
    fontSize: 20,
    lineHeight: 26,
    fontWeight: "500",
    paddingLeft: 16
  },
  flatList: {
    marginTop: 54
  },
  item: {
    marginHorizontal: 5,
    paddingTop: 30,
    paddingBottom: 16,
    paddingHorizontal: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  icon1: {
    height: 56,
    width: 56,
    ...Platform.select({
      web: {
        marginBottom: 8,
        position: "absolute",
        borderColor: "#D0D2D6",
        top: -30
      },
      android: {
        position: "relative",
        top: -10
      }
    }),
    borderColor: colors.Grey[3],
    borderRadius: 4,
    shadowColor: "#000000",
    shadowOpacity: 0.05,
    shadowRadius: 2,
    shadowOffset: {
      height: 3,
      width: 0
    }
  },
  icon2: {
    height: 36,
    width: 36,
    ...Platform.select({
      web: {
        marginTop: -20,
        marginBottom: 10,
        marginLeft: 18
      },
      android: {
        marginTop: 18,
        marginBottom: 25,
        marginLeft: 10
      }
    })
  },
  text: {
    textAlign: "center",
    color: globalStyles.colors.paper.primary
  },
  first: {
    marginLeft: 16
  },
  last: {
    marginRight: 16
  }
});

export default PopularCitiesJobs;
