import { pushClevertapEvent } from "utils/clevertapEvents";
import formatDate from "utils/formatDate";
import { getLocationClevertapProps } from "screens/common/utils";

export const getQueryFilter = ({ gender, faIds = [], quickFilters = {} }) => {
  const subFilter = [];

  if (faIds?.length) {
    subFilter.push({
      OR: [
        {
          functional_area: {
            id__in: [...faIds]
          }
        }
      ]
    });
  }

  if (gender) {
    subFilter.push({
      OR: [
        {
          expectation: {
            gender_preference__in: [gender.toUpperCase(), "ANY"]
          }
        }
      ]
    });
  }

  if (quickFilters.trusted_jobs) {
    subFilter.push({
      OR: [
        {
          pricing_plan_type__in: ["PREPAID", "POSTPAID", "TEMP_STAFFING"]
        }
      ]
    });
  }

  if (quickFilters.fresher_job) {
    subFilter.push({
      OR: [
        {
          expectation: {
            work_exp_requirements: {
              min_experience: 0
            }
          }
        }
      ]
    });
  }

  if (quickFilters.work_from_home) {
    subFilter.push({
      OR: [
        {
          employment_type: "WORK_FROM_HOME"
        }
      ]
    });
  }

  if (quickFilters.part_time) {
    subFilter.push({
      OR: [
        {
          employment_type: "PART_TIME"
        }
      ]
    });
  }

  if (quickFilters.client_approval_not_required) {
    subFilter.push({
      AND: [
        {
          client_approval_required: false
        },
        {
          is_screening_required: false
        },
        {
          search_metrics: {
            slots: {
              start_dt__gte: formatDate("YYYY-MM-DD")
            }
          }
        }
      ]
    });
  }

  return subFilter.length ? { AND: subFilter } : [];
};

export const handleClevertapEvent = async (
  { edges = {}, totalCount, jobSource = "" },
  locationIs,
  user,
  keywordArray,
  faIds,
  flexiRibbon,
  showEarningsCalculator,
  userRole
) => {
  const firstThreeCompanyTitle = [];
  const firstThreeJobTitle = [];
  const firstThreeFATitle = [];
  const firstFiveJobPricingPlanType = [];
  let paidJobCount = 0;
  const totalJobID = [];
  const totalJobID1 = [];

  edges.forEach((job, index) => {
    if (index < 3) {
      firstThreeJobTitle.push(job.title);
      firstThreeCompanyTitle.push(job.organization.name);
      firstThreeFATitle.push(job.functional_area.name);
    }
    // for first five job store pricing plan of that job
    if (index < 5 && job && job.pricing_plan_type) {
      firstFiveJobPricingPlanType.push(job.pricing_plan_type);
    }
    // if pricing plan of job is not free and jobPosting, job is paid
    if (
      job &&
      job.pricing_plan_type &&
      job.pricing_plan_type !== "FREE" &&
      job.pricing_plan_type !== "JOB_POSTING"
    ) {
      paidJobCount += 1;
    }

    if (index < 20) {
      totalJobID.push(job.id);
    } else if (index <= 40) {
      totalJobID1.push(job.id);
    }
  });

  const locationProps = await getLocationClevertapProps(
    locationIs?.locality,
    user
  );
  // console.log("NativeDisplayEmitter Page Load");

  // current list clevertap events
  pushClevertapEvent("Page Load", {
    Page_Modal: "Home",
    Current_Page: "home",
    Keywords: keywordArray.toString(),
    Location: locationIs ? locationIs.locality : "",
    "Number of results": totalCount,
    "First 20 JobIds": totalJobID?.toString(),
    "Next 20 JobIds": totalJobID1?.toString(),
    "Job Source": jobSource,
    "First Three Company": firstThreeCompanyTitle.toString(),
    "First Three Job Title": firstThreeJobTitle.toString(),
    "First Three FA": firstThreeFATitle.toString(),
    "User ID": user ? user.id : "NA",
    "Number of Paid job Cards": paidJobCount,
    "Pricing Plan Job Card 1": firstFiveJobPricingPlanType[0] || "NULL",
    "Pricing Plan Job Card 2": firstFiveJobPricingPlanType[1] || "NULL",
    "Pricing Plan Job Card 3": firstFiveJobPricingPlanType[2] || "NULL",
    "Pricing Plan Job Card 4": firstFiveJobPricingPlanType[3] || "NULL",
    "Pricing Plan Job Card 5": firstFiveJobPricingPlanType[4] || "NULL",
    "Flexi ribbon": flexiRibbon ? "Yes" : "No",
    "Flexi Earnings": userRole === 4 ? "Yes" : "No",
    Earnings_calculator: showEarningsCalculator ? "Yes" : "No",
    FA_Count: faIds?.length || keywordArray.length,
    ...locationProps
  });
};
