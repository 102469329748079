import React from "react";
import { View, StyleSheet } from "react-native";
import PropTypes from "prop-types";
import NavLink from "utils/routing/NavLink";
import SText from "styled42/components/SText";
import { pushClevertapEvent } from "utils/clevertapEvents/cleverTap";
import S3Image from "root/screens/common/S3Image";
import Colors from "styled42/config/colors";
import {
  storeDataToAsyncStorage,
  retrieveDataFromAsyncStorage
} from "screens/common/utils";
// import ApplicationCountBadge from "screens/common/ApplicationCountBadge";
import VernacManager from "utils/vernacular";
import IconToggle from "./RippleComponent";
import { getCookie } from "./session";

const getIconName = (tabTitle, isActive) => {
  const titleLowerCase = tabTitle.split(" ").join("").toLowerCase();
  return isActive ? `${titleLowerCase}-active` : `${titleLowerCase}-inactive`;
};

const TabFooter = ({ selectedTab, containerStyle, notifyUser }) => {
  const isLoggedIn = !!getCookie("loginaj");
  const tabList = [
    {
      title: "Home",
      route: isLoggedIn ? "dashboard" : "home",
      cleverTapEventType: "Home",
      vernacKey: "navbar_home"
    },
    {
      title: "Explore Jobs",
      route: "exploreJobList",
      cleverTapEventType: "Explore Jobs",
      vernacKey: "navbar_explore_jobs"
    },
    // {
    //   title: "Jobs",
    //   route: "matchingJobs",
    //   cleverTapEventType: "Jobs"
    // },
    {
      title: "Applications",
      route: "applications",
      cleverTapEventType: "Applications",
      vernacKey: "navbar_explore_applications"
    },
    {
      title: isLoggedIn ? "Profile" : "Login",
      route: isLoggedIn ? "profile" : "dashboard",
      cleverTapEventType: "Profile",
      vernacKey: isLoggedIn ? "profile_header" : "navbar_explore_login"
    }
  ];

  return (
    <View style={[styles.container, containerStyle]}>
      {tabList.map((tabItem) => {
        const isActive = tabItem.title === selectedTab;
        const tabIconName = getIconName(tabItem.title, isActive);
        const { vernacKey } = tabItem;
        const tabTextProps = isActive
          ? {
              size: 10,
              color: Colors.Orange[5],
              weight: "bold"
            }
          : {
              size: 10,
              color: Colors.Grey[8],
              weight: "normal"
            };

        return (
          <View key={tabItem.title} style={styles.itemContainer}>
            <NavLink
              route={tabItem.route}
              onClick={async () => {
                const variantValue = "A";
                // commenting out the variant due to breaking code
                // await retrieveDataFromAsyncStorage(
                //   "exploreJobsVariant"
                // );
                if (tabItem.title === "Profile") {
                  storeDataToAsyncStorage("notifyUserNavbar", false);
                }
                pushClevertapEvent("General Clicks", {
                  Type: tabItem.cleverTapEventType,
                  Navigation_bar: true,
                  Test_case:
                    tabItem.cleverTapEventType === "Explore Jobs"
                      ? variantValue
                      : undefined
                });
              }}
            >
              <IconToggle>
                <View style={styles.itemBox}>
                  <S3Image
                    path="RocketIntegration"
                    name={tabIconName}
                    isLazyLoadRequired={false}
                    style={{ width: 21, height: 21 }}
                  />
                  <SText
                    {...tabTextProps}
                    lineHeight={20}
                    transform="uppercase"
                  >
                    {VernacManager.translateString(vernacKey)}
                  </SText>
                  {tabItem.title === "Profile" && notifyUser && (
                    <View style={styles.notificationBadge} />
                  )}
                  {/* {tabItem.title === "Applications" && (
                    <ApplicationCountBadge
                      isUserInApplicationScreen={selectedTab === "Applications"}
                      containerStyle={{
                        position: "absolute",
                        top: 2,
                        right: 0
                      }}
                    />
                  )} */}
                </View>
              </IconToggle>
            </NavLink>
          </View>
        );
      })}
    </View>
  );
};

TabFooter.defaultProps = {
  selectedTab: "notselected"
};

TabFooter.propTypes = {
  selectedTab: PropTypes.string
};

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "white",
    shadowColor: "#000000",
    shadowOpacity: 0.1,
    shadowRadius: 2,
    shadowOffset: {
      height: -2,
      width: 0
    },
    zIndex: 1,
    // position: "fixed",
    bottom: 0,
    left: 0,
    right: 0
  },
  itemContainer: {
    justifyContent: "center",
    flex: 1
  },
  notificationBadge: {
    position: "absolute",
    width: 10,
    height: 10,
    top: 5,
    right: 0,
    borderRadius: "50%",
    backgroundColor: "#07959E"
  },
  itemBox: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    paddingTop: 4
  },
  flexStyle: {
    flex: 1
  }
});

export const withCustomTabFooter =
  (CustomTabFooter) => (WrappedComponent) => (navProps) =>
    class extends React.PureComponent {
      constructor(props) {
        super(props);
        this.state = {
          notifyUser: false
        };
      }

      async componentDidMount() {
        const notifyUser = await retrieveDataFromAsyncStorage(
          "notifyUserNavbar"
        );
        this.setState({ notifyUser });
      }

      render() {
        const { selected, getBannerCookie, ...restProps } = this.props;
        const { notifyUser } = this.state;

        return (
          <View style={styles.flexStyle}>
            <WrappedComponent {...restProps} />
            <CustomTabFooter
              selected={selected}
              {...navProps}
              notifyUser={notifyUser}
            />
          </View>
        );
      }
    };

const withTabFooter = (WrappedComponent) => (navProps) =>
  withCustomTabFooter(TabFooter)(WrappedComponent)(navProps);

export default withTabFooter;
