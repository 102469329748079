import React from "react";
import { StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import SExpansionPanel from "styled42/components/SExpansionPanel";
import SText from "styled42/components/SText";
import { pushClevertapEvent } from "utils/clevertapEvents";
import NavLink from "utils/routing/NavLink";
import colors from "styled42/config/colors";
import { socialLinks } from "screens/HomePage/utils";
import config from "config";
import VernacManager from "utils/vernacular";

const Footer = () => {
  const expansionPanelData = [
    {
      title: VernacManager.translateString("footer_quick_links"),
      links: [
        {
          name: VernacManager.translateString("footer_search_jobs"),
          route: "jobSearch",
          params: {
            slug: "jobs"
          }
        },
        {
          name: VernacManager.translateString("footer_jobs_by_city"),
          route: "jobsByCity"
        },
        {
          name: VernacManager.translateString("footer_jobs_by_company"),
          route: "jobsByCompany"
        },
        // {
        //   name: "Sarkari Jobs",
        //   route: "sarkariJobsHome"
        // },
        {
          name: VernacManager.translateString("footer_register_now"),
          route: "loginRegistration"
        },
        {
          name: VernacManager.translateString("footer_candidate_login"),
          route: "loginRegistration"
        },
        // {
        //   name: "Skills Training courses",
        //   href: "https://www.hunarr.co.in/",
        //   rel: "nofollow",
        //   prefetch: false
        // },
        {
          name: VernacManager.translateString("footer_knowledge_base"),
          route: "/knowledge-base/job-seeker/"
        },
        {
          name: VernacManager.translateString("footer_report_problem"),
          route: "/contact/"
        },
        {
          name: VernacManager.translateString("footer_faqs"),
          route: "/employee/faq/"
        }
      ]
    },
    {
      title: VernacManager.translateString("footer_employer_zone"),
      links: [
        {
          name: VernacManager.translateString("footer_post_jobs"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/register/`,
          prefetch: false,
          target: "_blank"
        },
        {
          name: VernacManager.translateString("footer_register_now"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/register/`,
          prefetch: false,
          target: "_blank"
        },
        {
          name: VernacManager.translateString("footer_employer_login"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}`,
          prefetch: false,
          target: "_blank"
        },
        {
          name: VernacManager.translateString("footer_free_job_posting"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/register/`,
          prefetch: false,
          target: "_blank"
        },
        {
          name: VernacManager.translateString("footer_premium_job_posting"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/products/job-posting/`,
          prefetch: false,
          target: "_blank"
        },
        // {
        //   name: "Startups to work with 2019",
        //   route: "/employer/startup-to-work-for-in-2019/"
        // },
        {
          name: VernacManager.translateString("footer_knowledge_base"),
          href: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/knowledge-base/recruiter/`,
          prefetch: false,
          target: "_blank"
        },
        // {
        //   name: "Report a Problem",
        //   route: "/contact/"
        // },
        {
          name: VernacManager.translateString("footer_faqs"),
          route: `${config.EMPLOYER_DESKTOP_WEB_BASE_URL}/faq/`
        }
      ]
    },
    {
      title: VernacManager.translateString("footer_more_information"),
      links: [
        {
          name: VernacManager.translateString("footer_about_us"),
          route: "/about-us/"
        },
        // {
        //   name: "Careers",
        //   route: "/careers/"
        // },
        {
          name: VernacManager.translateString("footer_contact_us"),
          route: "/contact/"
        },
        {
          name: VernacManager.translateString("footer_sitemap"),
          route: "/sitemap/"
        }
      ]
    }
  ];

  return (
    <View style={styles.bg}>
      {/* <View style={styles.sarkariText}>
      <SText size={14} weight="500" color="secondary">
        About Sarkari Jobs in India 2020
      </SText>
      <SText
        size={12}
        color="secondary"
        containerStyle={styles.sarkariParaText}
      >
        Government jobs in India have always been and will continue to be
        heavily favoured by a vast majority of job seekers. Unlike private jobs,
        a Sarkari job has its own benefits. Take salaries for example. A Sarkari
        Naukri might not pay as much as private jobs in India, but as a
        government employee, you can get standardized increments and bonuses.
      </SText>
      <SText
        size={12}
        color="secondary"
        containerStyle={styles.sarkariParaText}
      >
        As such, you need a trusted and a state-of-the-art Sarkari job portal in
        your corner to help you out. This is where the Rocket govt job
        portal steps in.
      </SText>
      <SText size={14} weight="500" color="secondary">
        Why Rocket for Sarkari Naukri jobs?
      </SText>
      <SText
        size={12}
        color="secondary"
        containerStyle={styles.sarkariParaText}
      >
        Job seekers must be efficient, alert, and meticulous when applying to
        the latest government jobs in India. Faltering in the application
        process, missing deadlines, or not following up can result in your
        application being rejected — you don’t want that at any cost.
      </SText>
      <SText
        size={12}
        color="secondary"
        containerStyle={styles.sarkariParaText}
      >
        With our portal, you can be sure of successfully applying, for the
        features and the latest information on government jobs that we provide
        to you is second to none. Let’s take a look at what you get out of
        Rocket for all things related to a Sarkari Naukri.
      </SText>
    </View> */}
      {expansionPanelData.map(({ title, links }) => (
        <SExpansionPanel
          key={`panel-${title}`}
          title={title}
          containerStyle={styles.footerContainer}
          titleContainerStyle={{ backgroundColor: "#f2f3f5" }}
          iconProps={{ color: colors.Grey[2] }}
        >
          {links.map(({ name, ...restProps }) => (
            <NavLink
              key={`link-${name}`}
              {...restProps}
              onClick={() => {
                pushClevertapEvent("Footer Clicks", {
                  Section: title,
                  Type: name
                });
              }}
            >
              <SText
                color="rgba(0,20,67,1.00)"
                containerStyle={styles.footerText}
              >
                {name}
              </SText>
            </NavLink>
          ))}
        </SExpansionPanel>
      ))}
      <View style={styles.followContainer}>
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderBottomColor: colors.Grey[12]
          }}
        >
          <View style={{ marginTop: 30 }}>
            <View style={{ flexDirection: "row" }}>
              <SText
                color="#FF9518"
                size={16}
                weight="bold"
                containerStyle={{ lineHeight: 19 }}
              >
                {VernacManager.translateString("footer_badi_companiyon")}
              </SText>
              <SText
                color="#deffffff"
                size={16}
                weight="bold"
                containerStyle={{ marginLeft: 6, lineHeight: 19 }}
              >
                {VernacManager.translateString("footer_ki_badi")}
              </SText>
            </View>
            <SText
              color="#deffffff"
              weight="bold"
              size={16}
              containerStyle={{ lineHeight: 19 }}
            >
              {VernacManager.translateString("footer_jobs_chahiye")}
            </SText>
            <SText color="#B8D0FC" size={12} containerStyle={{ marginTop: 12 }}>
              {VernacManager.translateString("footer_career_growth")}
            </SText>
            <SText color="#B8D0FC" size={12} containerStyle={{ marginTop: 8 }}>
              {VernacManager.translateString("footer_better_salary")}
            </SText>
            <View style={styles.downloadContainer}>
              <SText size={10} color="#B8D0FC">
                {VernacManager.translateString("footer_get_mobile_app")}
              </SText>
              <View style={styles.imageBox}>
                <NavLink
                  href="https://play.google.com/store/apps/details?id=com.aasaanjobs.employee.aasaanjobsemployee"
                  target="_blank"
                  rel="nofollow"
                  prefetch={false}
                  onClick={() => {
                    pushClevertapEvent("App download clicks", {
                      Type: "footer"
                    });
                  }}
                >
                  <S3Image name="playstore" style={styles.image} />
                </NavLink>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 16 }}>
            <S3Image
              name="celebrity_footer_banner"
              path="RocketIntegration"
              style={{ height: 189, width: 169 }}
            />
          </View>
        </View>

        <SText
          size={12}
          color="#deffffff"
          containerStyle={{ alignSelf: "center", marginTop: 16 }}
        >
          {VernacManager.translateString("footer_follow_us")}
        </SText>
        <View style={styles.iconContainer}>
          {socialLinks.map(({ name, imageName, href }) => (
            <View key={`icon-${name}`} style={styles.iconBox}>
              <NavLink
                href={href}
                target="_blank"
                rel="nofollow"
                prefetch={false}
                onClick={() => {
                  pushClevertapEvent("Footer Clicks", {
                    Section: "Follow",
                    Type: name
                  });
                }}
              >
                <S3Image
                  path="OlxIntegration"
                  name={imageName}
                  style={styles.icon}
                />
              </NavLink>
            </View>
          ))}
        </View>

        <View style={styles.flexContainer}>
          <NavLink
            route="/terms/"
            onClick={() => {
              pushClevertapEvent("Footer Clicks", {
                Type: "Terms and Conditions"
              });
            }}
          >
            <SText size={12} color="#B8D0FC">
              {VernacManager.translateString("footer_terms_conditions")}
            </SText>
          </NavLink>
          <NavLink
            route="privacyPolicy"
            onClick={() => {
              pushClevertapEvent("Footer Clicks", {
                Type: "Privacy Policy"
              });
            }}
          >
            <SText size={12} color="#B8D0FC">
              {VernacManager.translateString("footer_privacy_policy")}
            </SText>
          </NavLink>
        </View>
        <View
          style={{
            height: 1,
            width: "99%",
            backgroundColor: "rgba(255, 255, 255, 0.12)"
          }}
        />
        <View style={styles.copyrightContainer}>
          <SText size={12} color="#B8D0FC">
            {VernacManager.translateString("footer_copyright")}
          </SText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bg: {
    backgroundColor: "#0D111C"
  },
  sarkariText: {
    backgroundColor: colors.Grey[2],
    borderBottomWidth: 1,
    borderBottomColor: colors.Grey[3],
    paddingTop: 32,
    paddingBottom: 8,
    paddingHorizontal: 16
  },
  sarkariParaText: {
    marginTop: 8,
    marginBottom: 24
  },
  followContainer: {
    paddingHorizontal: 20,
    backgroundColor: "#0D1F46"
  },
  iconContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16
  },
  iconBox: {
    margin: 7,
    paddingTop: 8
  },
  icon: {
    height: 32,
    width: 32
    // opacity: 0.3
  },
  followText: {
    fontSize: 12,
    lineHeight: 14,
    color: "#ffffff",
    opacity: 0.8
  },
  downloadContainer: {
    paddingTop: 34
    // paddingBottom: 20
  },
  imageBox: {
    marginTop: 9
  },
  image: {
    height: 28,
    width: 95
  },
  flexContainer: {
    // paddingTop: 48,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 18
  },
  copyrightContainer: {
    paddingVertical: 17,
    // paddingHorizontal: 20,
    // marginHorizontal: 20,
    borderTopWidth: 1,
    borderTopColor: colors.Grey[12],
    flexDirection: "column",
    alignItems: "center"
    // marginBottom: 55
  },
  footerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors.Grey[3],
    backgroundColor: "#f2f3f5"
  },
  footerText: {
    fontSize: 14,
    lineHeight: 32
  }
});

export default Footer;
