import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import SText from "styled42/components/SText";
import S3Image from "screens/common/S3Image";
import { Colors } from "styled42/config";
import SButton from "styled42/components/SButton";
import { navigate } from "utils/routing/NavRouter";
import VernacManager from "utils/vernacular";
import { normalScale } from "utils/dimensions";

const translateFAs = (fas) => {
  if (fas) {
    const FAList = fas.split(",");
    const newFAList = FAList.map((fa) => VernacManager.translateString(fa));
    return newFAList.join(", ");
  }
  return fas;
};

const EmptyState = (props) => {
  const { containerStyle, referrer, fas } = props;
  const translatedFas = translateFAs(fas);

  return (
    <View style={[styles.container, containerStyle]}>
      <S3Image
        style={styles.icon}
        path="OlxIntegration"
        name="empty-jobs-state"
      />
      <SText
        style={{ marginTop: 30, textAlign: "center" }}
        size={14}
        color="primary"
      >
        {VernacManager.translateStringWithValues("search_listing_no_jobs", [
          translatedFas
        ])}
      </SText>
      <SText size={12} color={Colors.Grey[5]} containerStyle={styles.margin}>
        {VernacManager.translateString("search_listing_explore_more_jobs")}
      </SText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    paddingHorizontal: 24,
    paddingTop: normalScale(5)
  },
  margin: {
    marginTop: 8
  },
  icon: {
    height: 128,
    width: 128
  },
  buttonContainer: {
    width: "100%"
  }
});

export default EmptyState;
