import React from "react";
import HomePage from "screens/HomePage";
import withAuthToken from "lib/withAuthToken";
import { graphQLQueryHelper } from "utils/graphQL";
import { SEARCH_JOB_QUERY } from "screens/JobList/query";
import { getCookie } from "../lib/session";
import withTabFooter from "../lib/withTabFooter";

const HomePageLayout = (props) => {
  const HomePageWithTabFooter = withTabFooter(HomePage)({
    selectedTab: "Home"
  });

  return <HomePageWithTabFooter {...props} />;
};

const recentSearchFromCookie = (req) => {
  const recentSearch = getCookie("RecentSearch", req);
  const decodeURi = recentSearch ? decodeURIComponent(recentSearch) : null;

  return decodeURi ? JSON.parse(decodeURi).slice(0, 3) : [];
};

const getRecentSearchFAsFromCookie = (req) => {
  const recentSearches = recentSearchFromCookie(req);
  let recentSearchFAs = [];
  if (recentSearches && recentSearches.length > 0) {
    for (let i = 0; i < recentSearches.length; i++) {
      const { jobFAs } = recentSearches[i];
      if (jobFAs && jobFAs.length > 0) {
        recentSearchFAs = [...recentSearchFAs, ...jobFAs];
      }
    }
    // Set is used to remove duplicate values
    recentSearchFAs = [...new Set(recentSearchFAs)];
  }

  return recentSearchFAs.slice(0, 3);
};

HomePageLayout.getInitialProps = async ({ req, apolloClient }) => {
  const locationIs = getCookie("locationIs", req);
  const getBannerCookie = getCookie("homepageAppDownloadBanner", req);
  const decodeURiLocation = locationIs ? decodeURIComponent(locationIs) : null;
  const parseLocation = decodeURiLocation
    ? JSON.parse(decodeURiLocation)
    : null;
  const locationForQuery = parseLocation?.name?.split(",")[0];
  const faKeyword = getRecentSearchFAsFromCookie(req);
  const payload = {
    location: locationForQuery,
    after: 0,
    first: 3,
    searchKeyword: faKeyword && faKeyword.length > 0 ? faKeyword[0] : ""
  };

  if (locationIs && faKeyword && faKeyword.length > 0) {
    const { data, error } = await graphQLQueryHelper(apolloClient)({
      query: SEARCH_JOB_QUERY,
      variables: { ...payload }
    });

    if (error) {
      console.log("Error in suggest group query ", error);
    }

    if (data) {
      return {
        data,
        getBannerCookie
      };
    }
  }
};

export default withAuthToken(HomePageLayout);
