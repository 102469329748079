import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import HTag from "styled42/components/HTag";
import SClickableArea from "styled42/components/SClickableArea";
import SText from "styled42/components/SText";
import { Colors } from "styled42/config";
import VernacManager from "utils/vernacular";
import { pushClevertapEvent } from "utils/clevertapEvents";
import NavLink from "utils/routing/NavLink";
import { navigate } from "utils/routing/NavRouter";
import { LocationContext } from "../common/LocationContext";
import VoiceSearch from "../common/VoiceSearch";

const searchText = "Search Job, Company or Skills";
class HomeSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { compatable: false };
  }

  async componentDidMount() {
    if (Platform.OS === "web") {
      window.SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition || null;
      if (window.SpeechRecognition !== null) {
        this.setState({ compatable: true });
      }
    }
  }

  render() {
    const { showVoice, compatable } = this.state;
    const { isDashboard, onToggleSelectCity } = this.props;
    return (
      <View style={styles.bg}>
        <View style={styles.headerContainer}>
          <View style={styles.locationBlock}>
            <SClickableArea
              onClick={() => {
                pushClevertapEvent("Homepage Clicks", {
                  Section: "Top Widget",
                  Type: "Global Location Dropdown"
                });
                onToggleSelectCity(true);
              }}
            >
              <View style={styles.locationContainer} nativeID="locationButton">
                <View style={styles.locationView}>
                  <S3Image
                    path="RocketIntegration"
                    name="Location"
                    style={{ height: 16, width: 16 }}
                  />
                </View>
                <LocationContext.Consumer>
                  {({ locationIs }) => {
                    // Show only the city name
                    let locationText = "";
                    if (typeof locationIs.city === "object") {
                      locationText = locationIs?.city?.name;
                    } else {
                      locationText = locationIs?.city;
                    }

                    return (
                      <SText
                        size={12}
                        color="#13101D"
                        style={[
                          styles.locationName,
                          locationIs.name !== "India" &&
                            styles.locationCharacter
                        ]}
                      >
                        {locationIs.name === "India"
                          ? VernacManager.translateString(
                              "loggedout_select_work_location"
                            )
                          : locationText}
                      </SText>
                    );
                  }}
                </LocationContext.Consumer>
                <S3Image
                  path="RocketIntegration"
                  name="ChevronRight"
                  style={{ height: 15, width: 15, marginLeft: 5 }}
                />
              </View>
            </SClickableArea>
          </View>
          <View style={styles.aasaanLogo}>
            <S3Image
              path="RocketIntegration"
              name="Rocket"
              style={{ height: 18, width: 57 }}
            />
          </View>
          {/* <HomeLogin /> */}
        </View>
        {!isDashboard && (
          <View style={styles.searchContainer}>
            <NavLink
              route="jobSearch"
              prefetch={false}
              params={{ slug: "jobs" }}
              onClick={() => {
                pushClevertapEvent("Homepage Clicks", {
                  Type: "Search",
                  Section: "Top Widget"
                });
              }}
            >
              <View style={styles.search} nativeID="searchBox">
                <View style={styles.searchBox}>
                  <S3Image
                    path="RocketIntegration"
                    name="SearchIcon"
                    style={{
                      height: 16,
                      width: 16,
                      margin: 6,
                      marginLeft: 8
                    }}
                  />
                  <HTag tag="h1" style={{ margin: 8, lineHeight: 0 }}>
                    <SText style={styles.searchText}>
                      {VernacManager.translateString("loggedout_search_job")}
                    </SText>
                  </HTag>
                </View>
              </View>
            </NavLink>
          </View>
        )}
        {showVoice && compatable ? (
          <VoiceSearch
            FAname="Delivery Boy"
            setFalse={() => {
              this.setState({ showVoice: false });
            }}
            handleKeyboardClick={() => {
              this.setState({ showVoice: false });
              navigate("jobSearch", { slug: "jobs" });
            }}
          />
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 13,
    alignItems: "center",
    justifyContent: "space-between"
  },
  locationName: {
    fontWeight: "bold",
    ...Platform.select({
      web: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    }),
    overflow: "hidden"
  },
  locationCharacter: {
    ...Platform.select({
      web: {
        maxWidth: "10ch"
      }
    })
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 12
  },
  locationView: {
    marginRight: 8
  },
  searchText: {
    fontSize: 14,
    lineHeight: 20,
    color: "#676574"
  },
  chevronIcon: {
    paddingHorizontal: 2,
    paddingTop: 2
  },
  aasaanLogo: {
    flexDirection: "row",
    marginLeft: 8,
    marginRight: 16
  },
  searchContainer: {
    flex: 1,
    justifyContent: "center"
  },
  bg: {
    backgroundColor: "#F7F8F9"
  },
  micIcon: {
    flex: 1,
    paddingLeft: 15
  },
  searchBox: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF"
  },
  headerImage: { height: 24, width: 24 },
  search: {
    borderWidth: 1.5,
    borderColor: "#e4e6eb",
    borderRadius: 4,
    ...Platform.select({
      web: {
        margin: "auto",
        width: "calc(100% - 24px)",
        boxShadow: "0 0 4px 0 #e4e6eb"
      },
      android: {
        margin: 3,
        width: "100%"
      }
    })
  },
  iconSize: {
    width: 140,
    height: 32,
    marginTop: 20
  },
  logo: {
    opacity: 0.3,
    fontSize: 14,
    lineHeight: 20,
    color: Colors.BlueGrey[12],
    fontWeight: "900"
  }
});

export default HomeSearch;
