import React from "react";
import { StyleSheet, FlatList, View } from "react-native";
import STextField from "styled42/components/STextField";
import SText from "styled42/components/SText";
import SClickableArea from "styled42/components/SClickableArea";
import { Colors } from "styled42/config";
import S3Image from "screens/common/S3Image";
import { pushClevertapEvent } from "utils/clevertapEvents";
import CitySuggestion from "screens/LoginRegistration/components/LocationComponent/CitySuggestion";
import VernacManager from "utils/vernacular";
import { popularCities } from "./utils";

class Cities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }

  componentDidMount() {
    const { referrer } = this.props;
    pushClevertapEvent("Reg Pop-up Load", {
      Current_Page: referrer || "New loginRegistration",
      Page_Modal: "Location Select",
      Type: "Location Select Manual - City"
    });
  }

  _renderGridItem = ({ item }) => {
    return (
      <SClickableArea
        onClick={() => {
          const { referrer, setCity } = this.props;
          pushClevertapEvent(
            referrer === "home" ? "Homepage Clicks" : "loginRegister Clicks",
            {
              Current_Page: referrer || "New loginRegistration",
              Page_Modal: "Location Select",
              Type: "Location Selected - Popular City"
            }
          );

          setCity({
            cityName: item.name,
            location: item.location
          });
        }}
        style={styles.gridItem}
      >
        <S3Image
          path="RocketIntegration"
          name={item.iconName}
          style={styles.icon}
        />
        <SText color={Colors.Grey[7]} size={12}>
          {VernacManager.translateString(item.vernacKey)}
        </SText>
      </SClickableArea>
    );
  };

  listKeyExtractor = (item) => item.placeID;

  _renderPopularCities = () => {
    return (
      <>
        <SText color={Colors.Grey[12]} size={12}>
          {VernacManager.translateString("location_popular_job_city")}
        </SText>
        <FlatList
          keyExtractor={this.listKeyExtractor}
          data={popularCities}
          renderItem={this._renderGridItem}
          numColumns={3}
        ></FlatList>
      </>
    );
  };

  _renderCityList = (searchText) => {
    const { referrer, setCity } = this.props;
    return (
      <CitySuggestion
        searchText={searchText}
        referrer={referrer}
        setCity={setCity}
      />
    );
  };

  render() {
    const { searchText } = this.state;
    return (
      <View style={styles.citiesContainer}>
        <STextField
          placeholder={VernacManager.translateString(
            "location_search_job_city"
          )}
          value={searchText}
          onChangeText={(value) => {
            this.setState({ searchText: value });
          }}
        />
        {searchText === ""
          ? this._renderPopularCities()
          : this._renderCityList(searchText)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  citiesContainer: {
    height: 350
  },
  gridItem: { flex: 1, alignItems: "center", paddingTop: 16 },
  icon: { width: 48, height: 48 },
  loading: {
    height: 280,
    justifyContent: "center",
    alignItems: "center"
  },
  suggestionText: {
    paddingVertical: 8,
    marginLeft: 8
  }
});

export default Cities;
