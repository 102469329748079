import { Platform } from "react-native";
import gql from "graphql-tag";
import { mWarn } from "utils/logger";
import objectPath from "object-path";
import getLatLongPosition from "utils/location/getLatLongCoordinates";
import { graphQLQueryHelper, graphQLClientMutationHelper } from "utils/graphQL";
import { EDIT_CANDIDATE } from "screens/LoginRegistration/queries";
import getData from "utils/getData";
import { SetLocation } from "screens/common/LocationContext";
import { pushClevertapProfile } from "utils/clevertapEvents";
//import { triggerAdjustRegistrationEvent } from "utils/adjustEvents";
import { INITIAL_LOGGED_QUERY, SOURCES_LIST } from "root/lib/withAuthToken";

export const LocationComponentEnum = {
  CITIES: "CITIES",
  LOCALITIES: "LOCALITIES"
};

export const popularCities = [
  {
    name: "Mumbai",
    iconName: "Mumbai",
    placeID: "ChIJwe1EZjDG5zsRaYxkjY_tpF0",
    location: { lat: 19.0759837, lng: 72.8776559 },
    vernacKey: "location_page_mumbai"
  },
  {
    name: "New Delhi",
    iconName: "Delhi",
    placeID: "ChIJL_P_CXMEDTkRw0ZdG-0GVvw",
    location: { lat: 28.6862738, lng: 77.2217831 },
    vernacKey: "location_page_new_delhi"
  },
  {
    name: "Gurgaon",
    iconName: "Gurgaon",
    placeID: "ChIJWYjjgtUZDTkRHkvG5ehfzwI",
    location: { lat: 28.4594965, lng: 77.0266383 },
    vernacKey: "location_page_gurgaon"
  },
  {
    name: "Bengaluru",
    iconName: "Bangalore",
    placeID: "ChIJbU60yXAWrjsR4E9-UejD3_g",
    location: { lat: 12.9715987, lng: 77.5945627 },
    vernacKey: "location_page_bengaluru"
  },
  {
    name: "Hyderabad",
    iconName: "Hyderabad",
    placeID: "ChIJx9Lr6tqZyzsRwvu6koO3k64",
    location: { lat: 17.385044, lng: 78.486671 },
    vernacKey: "location_page_hyderabad"
  },
  {
    name: "Chennai",
    iconName: "Chennai",
    placeID: "ChIJYTN9T-plUjoRM9RjaAunYW4",
    location: { lat: 13.0826802, lng: 80.2707184 },
    vernacKey: "location_page_chennai"
  },
  {
    name: "Ahmedabad",
    iconName: "Ahmedabad",
    placeID: "ChIJSdRbuoqEXjkRFmVPYRHdzk8",
    location: { lat: 23.022505, lng: 72.5713621 },
    vernacKey: "location_page_ahmedabad"
  },
  {
    name: "Pune",
    iconName: "Pune",
    placeID: "ChIJARFGZy6_wjsRQ-Oenb9DjYI",
    location: { lat: 18.5204303, lng: 73.8567437 },
    vernacKey: "location_page_pune"
  },
  {
    name: "Kolkata",
    iconName: "Kolkata",
    placeID: "ChIJZ_YISduC-DkRvCxsj-Yw40M",
    location: { lat: 22.572646, lng: 88.36389500000001 },
    vernacKey: "location_page_kolkata"
  }
];

export const SEARCH_LOCALITY = gql`
  query locationSearch(
    $queryType: LocalitySearchType!
    $Latitude: Float
    $Longitude: Float
    $query: String
    $cityId: Int
  ) {
    searchLocality(
      query_type: $queryType
      latitude: $Latitude
      longitude: $Longitude
      query: $query
      city_id: $cityId
    ) {
      id
      name
      latitude
      longitude
      city {
        id
        name
      }
    }
  }
`;

export const getCurrentLocation = async (
  setFetchingLocation,
  setAskingPersmission,
  callback,
  apolloClient
) => {
  await getLatLongPosition(
    (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      getLocationFromLatLong(apolloClient, latitude, longitude, callback);
    },
    (error) => {
      if (error.code === 1 || error.code === 5) {
        callback(error);
      }
    },
    undefined,
    undefined,
    (permissionStatus) => {
      setAskingPersmission(permissionStatus);
      if (permissionStatus) {
        setFetchingLocation(true);
      }
    }
  );
};

export const getLocationFromLatLong = async (
  apolloClient,
  latitude,
  longitude,
  callback
) => {
  const { data } = await graphQLQueryHelper(apolloClient)({
    query: SEARCH_LOCALITY,
    variables: {
      queryType: "LOCATION",
      Latitude: latitude,
      Longitude: longitude
    }
  });
  if (callback) {
    setTimeout(() => {
      callback({ ...data, latitude, longitude });
    }, 1500);
  } else {
    return data.searchLocality.splice(0, 5);
  }
};

export const getLocationText = (formattedSuggestion) => {
  return `${formattedSuggestion.main_text}${
    formattedSuggestion.secondary_text &&
    `, ${formattedSuggestion.secondary_text.split(",")[0]}`
  }`;
};

export const deepGet = (object) => (path) => objectPath.get(object, path, null);

export const onSubmitHandler = async ({
  addressPayload,
  goNext,
  state,
  client,
  errorCallback
}) => {
  const { user } = state;

  const payload = addressPayload;
  console.log("address Payload Here before query", user, addressPayload);

  const { data, error } = await graphQLClientMutationHelper(client)({
    mutation: EDIT_CANDIDATE,
    variables: { id: user.stakeholder.id, payload }
  });

  if (error) {
    mWarn("error in EDIT_CANDIDATE", error);
    if (errorCallback) errorCallback(error);
    return;
  }

  if (data && data.edit) {
    const userData = {
      ...user,
      stakeholder: {
        ...user?.stakeholder,
        address: getData(data, "edit.address")
      }
    };

    const locationArray = payload.address.address_text.split(", ");

    pushClevertapProfile({
      Identity: user.id,
      Candidate_Locality: locationArray[0] ? locationArray[0] : "NA",
      Candidate_City: locationArray[1] ? locationArray[1] : "NA"
    });

    const locality = getData(data, "edit.address.locality");

    // ** IMPORTANT ***************************************************************
    // Change global location to profile location
    if (locality) {
      const { name, latitude, longitude, city = {} } = locality;
      SetLocation(
        "user_entered_location",
        name,
        latitude,
        longitude,
        city.name
      );
    }
    // ****************************************************************************

    //moved adjust onboarding completion event to after fa selection(showjobs-CTA)
    // if (Platform.OS === "android") {
    //   triggerAdjustRegistrationEvent("user_id", userData.id);
    // }

    goNext({
      user: userData
    });
  }
};
