import { retrieveDataFromAsyncStorage } from "screens/common/utils";

export const getRecentSearch = async () => {
  const recentSearches = await retrieveDataFromAsyncStorage("RecentSearch");
  return recentSearches ? recentSearches.slice(0, 3) : []; // show only the first 3 recent searches
};

export const getRecentSearchFAs = async () => {
  // This section extracts the unique jobFAs from recentSearch Array
  // RecentSearche is an array of object that looks like {jobFAs:[], location:""}
  const recentSearches = await getRecentSearch();
  let recentSearchFAs = [];
  if (recentSearches && recentSearches.length > 0) {
    for (let i = 0; i < recentSearches.length; i++) {
      let { jobFAs } = recentSearches[i];
      if (jobFAs && jobFAs.length > 0) {
        recentSearchFAs = [...recentSearchFAs, ...jobFAs];
      }
    }
    // Set is used to remove duplicate values
    recentSearchFAs = [...new Set(recentSearchFAs)];
  }

  return recentSearchFAs.slice(0, 3);
};
