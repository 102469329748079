import React from "react";
import { View, Animated, Easing, TouchableWithoutFeedback } from "react-native";

export default class IconToggle extends React.Component {
  constructor(props) {
    super(props);
    const maxOpacity = 0.12;

    this.state = {
      maxOpacity,
      scaleValue: new Animated.Value(0.01),
      opacityValue: new Animated.Value(maxOpacity)
    };

    this.renderRippleView = this.renderRippleView.bind(this);
    this.onPressedIn = this.onPressedIn.bind(this);
    this.onPressedOut = this.onPressedOut.bind(this);
  }

  onPressedIn() {
    const { scaleValue } = this.state;
    Animated.timing(scaleValue, {
      toValue: 1,
      duration: 180,
      easing: Easing.bezier(0.0, 0.0, 0.2, 1)
    }).start();
  }

  onPressedOut() {
    const { opacityValue, scaleValue, maxOpacity } = this.state;
    Animated.timing(opacityValue, {
      toValue: 0
    }).start(() => {
      scaleValue.setValue(0.01);
      opacityValue.setValue(maxOpacity);
    });
  }

  renderRippleView() {
    const { scaleValue, opacityValue } = this.state;

    return (
      <Animated.View
        style={{
          position: "absolute",
          width: 120,
          height: 120,
          borderRadius: 100,
          transform: [{ scale: scaleValue }],
          opacity: opacityValue,
          backgroundColor: "black"
        }}
      />
    );
  }

  render() {
    return (
      <TouchableWithoutFeedback
        onPressIn={this.onPressedIn}
        onPressOut={this.onPressedOut}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {this.renderRippleView()}
          {this.props.children}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
