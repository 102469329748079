import React from "react";
import { View, StyleSheet } from "react-native";
import { withApollo } from "@apollo/client/react/hoc";
import SText from "styled42/components/SText";
import S3Image from "screens/common/S3Image";
import { LocationContext } from "screens/common/LocationContext";
import SClickableArea from "styled42/components/SClickableArea";
import { pushClevertapEvent } from "utils/clevertapEvents";
import { Colors } from "styled42/config";
import VernacManager from "utils/vernacular";
import PopularCitiesJobsComponent from "./PopularCitiesJobs";
import { getCurrentLocation } from "./utils";

class ActiveJobsLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useBrowserLocation: false,
      browserLocation: {
        locationText: null,
        locationDenied: false
      }
    };
  }

  getBrowserLocationText = (currentLoc) => {
    const { browserLocation } = this.state;
    if (browserLocation.locationDenied) {
      return "Access to location blocked";
    }
    return browserLocation.locationText || currentLoc;
  };

  setLocation = (data, setCurrentLocation) => {
    const { browserLocation } = this.state;
    const { onToggleSelectCity } = this.props;
    if (data && data.searchLocality && data.searchLocality.length > 0) {
      const name = data.searchLocality[0].name;
      const localityId = data.searchLocality[0].id;
      const lat = data.searchLocality[0].latitude;
      const long = data.searchLocality[0].longitude;
      const city = data.searchLocality[0].city.name;
      let currentLocationText = name;
      if (city && name !== city) {
        currentLocationText = `${name}, ${city}`;
      }
      setCurrentLocation(
        "current_location",
        currentLocationText,
        lat,
        long,
        null,
        city
      );
      this.setState({
        useBrowserLocation: true,
        browserLocation: {
          ...browserLocation,
          localityId,
          locationText: currentLocationText
        }
      });
    } else if (data.code === 1) {
      this.setState({
        useBrowserLocation: false,
        browserLocation: {
          ...browserLocation,
          locationText: null,
          locationDenied: true
        }
      });
      onToggleSelectCity(true);
    }
  };

  renderActiveJobs = (location, setCurrentLocation) => {
    const { jobArray, onToggleSelectCity, client, referrer } = this.props;

    return (
      <View style={styles.bg}>
        <View style={styles.bannerContainer}>
          <View style={styles.headerContainer}>
            <View style={styles.header}>
              <SText size={16} lineHeight={24} weight="bold" color="#13101d">
                {VernacManager.translateString("loggedout_activejobs_nearyou")}
              </SText>
              <SText
                size={12}
                color="secondary"
                lineHeight={20}
                containerStyle={{ width: "70%" }}
              >
                {VernacManager.translateString("loggedout_suitable_nearyou")}
              </SText>
            </View>
            <S3Image
              path="RocketIntegration"
              name="Location_Nav"
              style={{ height: 70, width: 70 }}
            />
          </View>
          <SClickableArea
            onClick={async () => {
              pushClevertapEvent(
                referrer === "jobList" ? "General clicks" : "Homepage clicks",
                {
                  Current_Page: referrer === "jobList" ? referrer : "home",
                  Section:
                    referrer === "jobList"
                      ? "Select City - mid listing"
                      : "Top Cities",
                  Type: "Auto-detect Location"
                }
              );
              await getCurrentLocation((data) => {
                if (referrer === "jobList") {
                  const cityName = data?.searchLocality[0]?.city.name;
                  onToggleSelectCity(false, cityName);
                } else {
                  this.setLocation(data, setCurrentLocation);
                }
              }, client);
            }}
            style={styles.button}
          >
            <S3Image
              path="OlxIntegration/onboarding"
              name="current_location"
              style={styles.buttonIcon}
            />
            <SText style={styles.buttonText}>
              {this.getBrowserLocationText(
                VernacManager.translateString("loggedout_current_location")
              )}
            </SText>
          </SClickableArea>
          <PopularCitiesJobsComponent
            jobArray={jobArray}
            referrer={referrer}
            locationIs={location}
            onToggleSelectCity={onToggleSelectCity}
            client={client}
            setCurrentLocation={setCurrentLocation}
          />
        </View>
      </View>
    );
  };

  render() {
    const { jobLocation, referrer } = this.props;

    if (referrer === "jobList") {
      return this.renderActiveJobs(jobLocation);
    }
    return (
      <LocationContext.Consumer>
        {({ locationIs, setCurrentLocation }) => {
          const location = locationIs.name;
          return this.renderActiveJobs(location, setCurrentLocation);
        }}
      </LocationContext.Consumer>
    );
  }
}
const styles = StyleSheet.create({
  bg: {
    backgroundColor: "#f7f8f9",
    paddingTop: 10
  },
  bannerContainer: {
    paddingTop: 20,
    paddingHorizontal: 16,
    backgroundColor: "#f7f8f9"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 9
  },
  header: {
    flex: 1
  },
  button: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 48,
    backgroundColor: Colors.Purple[1],
    paddingHorizontal: 16,
    width: "100%",
    borderRadius: 4
  },
  buttonText: {
    fontWeight: "500",
    color: "white"
  }
});

export default withApollo(ActiveJobsLocation);
