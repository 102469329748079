import config from "config";

const loadMapsAutocompleteService = (cb) => {
  if (
    !window.autocompleteService &&
    document &&
    !document.getElementById("gmaps-api")
  ) {
    const autocomplete = document.createElement("script");
    autocomplete.id = "gmaps-api";
    autocomplete.async = true;

    autocomplete.src = `https://maps.googleapis.com/maps/api/js?key=${config.GMAPS_KEY}&libraries=places`;
    autocomplete.onload = () => {
      if (window.google && window.google.maps.places) {
        window.autocompleteService =
          new window.google.maps.places.AutocompleteService();
        window.autocompleteOK =
          window.google.maps.places.PlacesServiceStatus.OK;

        if (cb) {
          cb();
        }
      }
    };
    document.body.appendChild(autocomplete);
  } else if (cb) {
    cb();
  }
};

export default loadMapsAutocompleteService;
