import React from "react";
import { StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import GridLayout from "./GridLayout";
import { pushClevertapEvent } from "utils/clevertapEvents";
import NavLink from "utils/routing/NavLink";
import SText from "styled42/components/SText";
import { LocationContext } from "screens/common/LocationContext";
import { slugify } from "utils/parseUrl";
import VernacManager from "utils/vernacular";

const qualification = [
  {
    iconName: "below_10th_homepage_icon",
    text: "Below 10th",
    education: 1,
    vernacKey: "generic_below_10"
  },
  {
    iconName: "10th_pass_homepage_icon",
    text: "10th Pass",
    education: 2,
    vernacKey: "generic_pass_10"
  },
  {
    iconName: "12th_pass_homepage_icon",
    text: "12th Pass",
    education: 3,
    vernacKey: "generic_pass_12"
  },
  {
    iconName: "undergraduate_homepage_icon",
    text: "Undergraduate",
    education: 4,
    vernacKey: "loggedout_under_graduate"
  },
  {
    iconName: "graduate_homepage_icon",
    text: "Graduate",
    education: 5,
    vernacKey: "generic_graduate"
  },
  {
    iconName: "post_graduate_homepage_icon",
    text: "Post Graduate",
    education: 6,
    vernacKey: "generic_post_graduate"
  }
];

const GridItem = ({ item, locationIs }) => (
  <View style={styles.componentContainer}>
    <NavLink
      route="jobList"
      params={{
        slug: `jobs-in-${locationIs}`,
        education: item.education
      }}
      onClick={() => {
        pushClevertapEvent("Homepage clicks", {
          Page_Modal: "Home",
          Section: "Education",
          Type: item.text
        });
      }}
    >
      <View style={styles.content}>
        <S3Image
          path="OlxIntegration"
          name={item.iconName}
          style={styles.icon}
        />
        <SText
          size={14}
          lineHeight={16}
          color="#868594"
          containerStyle={{ marginTop: 8 }}
        >
          {VernacManager.translateString(item.vernacKey)}
        </SText>
      </View>
    </NavLink>
  </View>
);

class JobsByEducation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LocationContext.Consumer>
        {({ locationIs }) => {
          const locationText = locationIs?.name?.split(",")[0];
          const location = slugify(locationText);
          return (
            <GridLayout
              gridItemArray={qualification}
              row={2}
              column={3}
              headerText={`${locationText} ${VernacManager.translateString(
                "loggedout_jobs_by_education"
              )} `}
              GridItem={GridItem}
              locationIs={location}
            />
          );
        }}
      </LocationContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  componentContainer: {
    flex: 1,
    paddingVertical: 16,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "#d6f1f7",
    backgroundColor: "#fff"
  },
  content: {
    flexDirection: "column",
    alignItems: "center"
  },
  icon: {
    width: 48,
    height: 48,
    marginVertical: 4
  },
  text: {
    textAlign: "center",
    fontSize: 11,
    lineHeight: 16,
    color: "#030f09"
  }
});
export default JobsByEducation;
