import dynamic from "next/dynamic";
import React from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { ScrollView, StyleSheet, View, Dimensions } from "react-native";
import globalStyles from "styled42/config/globalStyles.config";
import SLoader from "styled42/components/SLoader";
import S3Image from "screens/common/S3Image";
import NavLink from "utils/routing/NavLink";
import config from "config";
import FooterSection from "screens/common/Footer";
import { getRecentSearchFAs } from "utils/recentSearch";
import ListView from "./ListView";
import {
  SetLocation,
  GetLocation,
  LocationContext
} from "../common/LocationContext";
import HomeSearch from "./HomeSearch";
import JobCategory from "./JobCategory";
import SEOHelper from "./SEOHelper";
import LookinToHire from "./LookingToHire";
import TopCompanyJobs from "./TopCompanyJobs";
import Footer from "./Footer";
import ActiveJobsLocation from "./ActiveJobsLocation";
import HowItWorks from "./HowItWorks";
import JobsByExperience from "./JobsByExperience";
import LocationPicker from "./LocationPicker";
import JobsByEducation from "./JobsByEducation";

const DeviceWidth = Dimensions.get("window").width;

const LoadingState = () => (
  <View style={{ paddingVertical: 16 }}>
    <SLoader />
  </View>
);

const DynamicWalkIn = dynamic(
  import(/* webpackChunkName: "dynamicWalkIn" */ "screens/HomePage/WalkIn"),
  {
    ssr: true,
    loading: () => <LoadingState />
  }
);

// TODO: Make recent search chip and recent search card as two separate components
// const DynamicRecentSearch = dynamic(
//   import(
//     /* webpackChunkName: "dynamicRecentSearch" */ "screens/HomePage/RecentSearch"
//   ),
//   {
//     ssr: true,
//     loading: () => <LoadingState />
//   }
// );

const EmployerBanner = () => {
  return (
    <NavLink
      href={config.EMPLOYER_DESKTOP_WEB_BASE_URL}
      rel="nofollow"
      prefetch={false}
      target="_blank"
      onClick={() => {
        // pushClevertapEvent("Homepage Clicks", "Employer Banner");
      }}
    >
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eeeef6"
        }}
      >
        <S3Image
          path="OlxIntegration"
          name="employer_job"
          type="png"
          islazyLoadRequired={false}
          style={{
            width: 360,
            height: 118,
            backgroundColor: "#eeeef6"
          }}
        />
      </View>
    </NavLink>
  );
};

class HomePageScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationIs: {},
      jobArray: [],
      isLocationPickerVisible: false,
      route: "",
      params: null
    };
  }

  componentDidMount = async () => {
    const recentSearchFAs = await getRecentSearchFAs();
    this.setState({
      jobArray: recentSearchFAs
    });
    this.getLocation();
  };

  getLocation = async () => {
    const { client } = this.props;
    const locationIs = await GetLocation(client);
    if (locationIs) {
      this.setState({
        locationIs
      });
    }
  };

  setCurrentLocation = (key, value, lat, long, city) => {
    this.setState({ locationIs: { name: value } });
    SetLocation(key, value, lat, long, null, city);
  };

  onToggleSelectCity = async (flag, route, params) => {
    this.setState({
      isLocationPickerVisible: flag,
      route,
      params
    });
    if (!flag) setTimeout(() => this.getLocation(), 600);
  };

  render() {
    const { authToken, user, query, client, sources, data } = this.props;

    const { route, params, isLocationPickerVisible, locationIs, jobArray } =
      this.state;

    return (
      <LocationContext.Provider
        value={{ locationIs, setCurrentLocation: this.setCurrentLocation }}
      >
        <ScrollView contentContainerStyle={styles.container}>
          <HomeSearch onToggleSelectCity={this.onToggleSelectCity} />
          {data && (
            <ListView
              data={data}
              locationIs={locationIs}
              keywordArray={[jobArray[0]]}
              jobsOnFirstFetch={3}
              showSortOption={false}
              referrer="Homepage"
              authToken={authToken}
              query={query}
              user={user}
              sources={sources}
              client={client}
            />
          )}
          <JobCategory
            onToggleSelectCity={this.onToggleSelectCity}
            client={client}
          />
          {locationIs.name === "India" ? (
            <ActiveJobsLocation
              jobArray={jobArray}
              onToggleSelectCity={this.onToggleSelectCity}
            />
          ) : null}
          {locationIs && locationIs.name && locationIs.name !== "India" ? (
            <JobsByExperience locationIs={locationIs.name} />
          ) : null}
          {locationIs && locationIs.name && locationIs.name !== "India" ? (
            <JobsByEducation />
          ) : null}
          {LookinToHire()}
          <HowItWorks />
          {TopCompanyJobs()}
          {FooterSection()}

          {Footer()}
        </ScrollView>
        <LocationPicker
          isVisible={isLocationPickerVisible}
          onToggleSelectCity={this.onToggleSelectCity}
          route={route}
          params={params}
          referrer="home"
        />
        <SEOHelper />
      </LocationContext.Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: globalStyles.colors.Primary["000"]
  },
  bottomPadding: {
    height: 24,
    backgroundColor: globalStyles.colors.Primary[200]
  }
});

export default withApollo(HomePageScreen);
