import { Dimensions, PixelRatio, Platform } from "react-native";

const { width, height } = Dimensions.get("window");

const scale = width / 375;
// Use this for fontSize
function normalize(size) {
  return Math.round(scale * size);
}
function pixelNormalize(size) {
  const newSize = size * scale;

  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 360;
const guidelineBaseHeight = 640;

// Use this for left, right or horizontal values
const normalScale = (size) => (width / guidelineBaseWidth) * size;

// use this for vertical, top, bottom values
const verticalScale = (size) => (height / guidelineBaseHeight) * size;

// use this when both vertical and horizontal values
const moderateScale = (size, factor = 0.5) =>
  size + (normalScale(size) - size) * factor;

export {
  normalize,
  normalScale,
  moderateScale,
  verticalScale,
  width as deviceWidth,
  height as deviceHeight,
  pixelNormalize
};
