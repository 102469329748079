import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import SText from "styled42/components/SText";
import SActionCard from "styled42/components/SActionCard";
import S3Image from "screens/common/S3Image";
import { Colors } from "styled42/config";
import { pushClevertapEvent } from "utils/clevertapEvents";
import SClickableArea from "styled42/components/SClickableArea";
import { navigate } from "utils/routing/NavRouter";
import Cities from "screens/LoginRegistration/components/LocationComponent/Cities";
import loadMapsAutocompleteService from "utils/googleMapsAutoComplete";
import VernacManager from "utils/vernacular";
import { SetLocation } from "../../common/LocationContext";

class LocationPicker extends React.Component {
  componentDidMount() {
    if (Platform.OS === "web") {
      loadMapsAutocompleteService();
    }
  }

  setCity = async ({ cityName, location, placeId, searchText }) => {
    const { route, params, onToggleSelectCity, referrer } = this.props;

    if (referrer === "jobList" || referrer === "explore") {
      onToggleSelectCity(false, cityName);
      pushClevertapEvent("General Clicks", {
        Current_Page: "jobList",
        Location: cityName,
        Type: "Location Select"
      });
      return;
    }
    SetLocation(
      "user_entered_location",
      searchText || cityName,
      location.lat,
      location.lng,
      placeId,
      cityName
    );
    onToggleSelectCity(false);
    if (route) {
      let urlQuery = params === "undefined" ? "" : params.slug;
      urlQuery += `-in-${(searchText || cityName)
        .toLowerCase()
        .split(" ")
        .join("-")}`;
      setTimeout(() => navigate(route, { slug: urlQuery }), 600);
    }
  };

  onClickHandler = () => {
    const { onToggleSelectCity, referrer } = this.props;
    pushClevertapEvent(
      referrer === "jobList" ? "General clicks" : "Homepage Clicks ",
      {
        Current_Page: referrer === "jobList" ? referrer : "home",
        Page_Modal: "Location Select",
        Type: "Back - Location City Pop-up"
      }
    );
    onToggleSelectCity(false);
  };

  renderTopComponent = () => {
    return (
      <View style={styles.topComponent}>
        <SClickableArea onClick={this.onClickHandler}>
          <S3Image
            path="RocketIntegration"
            name="BackIcon"
            islazyLoadRequired={false}
            style={styles.iconStyle}
          />
        </SClickableArea>
        <SText
          color={Colors.Grey[12]}
          weight="bold"
          size={16}
          containerStyle={{ flex: 1 }}
        >
          {VernacManager.translateString("location_want_to_work")}
        </SText>
      </View>
    );
  };

  render() {
    const { isVisible, onClose, referrer } = this.props;
    return (
      <SActionCard
        visible={isVisible}
        scrollViewStyle={styles.actionCard}
        onCancel={onClose}
        topComponent={this.renderTopComponent()}
      >
        <Cities referrer={referrer} setCity={this.setCity} />
      </SActionCard>
    );
  }
}

const styles = StyleSheet.create({
  topComponent: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16
  },
  iconStyle: {
    width: 22,
    height: 22,
    marginRight: 16
  },
  actionCard: {
    padding: 16,
    paddingBottom: 16,
    backgroundColor: Colors.White[1]
  }
});
export default LocationPicker;
