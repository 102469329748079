import React, { useState, useEffect } from "react";
import { View, StyleSheet, Platform, Dimensions } from "react-native";
import { pushClevertapEvent } from "utils/clevertapEvents";
import S3Image from "screens/common/S3Image";
import NavLink from "utils/routing/NavLink";
import SText from "styled42/components/SText";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const HowItWorks = () => {
  const [dimensions, setDimensions] = useState({ window, screen });

  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen });
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  return (
    <View style={styles.bg}>
      <S3Image
        path="RocketIntegration"
        name="downloadApp_banner"
        style={styles.imageStyle}
      />
      <View
        style={[
          styles.downloadButtonContainer,
          {
            ...Platform.select({
              web: {
                top: dimensions.window.width < 330 ? "30%" : "27%",
                left: dimensions.window.width < 330 ? "55%" : "50%"
              }
            })
          }
        ]}
      >
        <NavLink
          href="https://play.google.com/store/apps/details?id=com.aasaanjobs.employee.aasaanjobsemployee&referrer=adjust_reftag%3Dcw8WuvpiO0QOk%26utm_source%3DWeb_banner_to_app%26utm_campaign%3DWeb_banner_to_app%26utm_content%3DWeb_banner_to_app"
          target="_blank"
          rel="nofollow"
          prefetch={false}
          onClick={() => {
            pushClevertapEvent("Homepage clicks", {
              Section: "How it works",
              Type: "Download App"
            });
          }}
        >
          <View style={styles.downloadStepsButton}>
            <SText style={{ color: "#fff", fontSize: 12 }}>
              Download FREE App
            </SText>
          </View>
        </NavLink>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  bg: {
    backgroundColor: "#F2F3F4",
    paddingTop: 40,
    position: "relative"
  },
  imageStyle: {
    width: "100%",
    height: 748
  },
  downloadButtonContainer: {
    position: "absolute",
    transform: [{ translateX: "-88%" }, { translateY: "-55%" }],
    shadowColor: "#C9CACA",
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 2
  },
  downloadStepsButton: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1B2D93",
    borderRadius: 8,
    width: 159,
    height: 40
  }
});

export default HowItWorks;
