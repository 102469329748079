import React from "react";
import { StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import SClickableArea from "styled42/components/SClickableArea";
import SText from "styled42/components/SText";
import colors from "styled42/config/colors";
import { pushClevertapEvent } from "utils/clevertapEvents";
import generateUniqueKey from "utils/generateUniqueKey";
import { sendPageClickEvent } from "utils/googleAnalyticsEvents";
import NavLink from "utils/routing/NavLink";
import VernacManager from "utils/vernacular";

const Block = ({
  index,
  FAname,
  imageName,
  imageWidth = 40,
  imageHeight = 40,
  imageType,
  route,
  params,
  newTag,
  location,
  isDashboard,
  onToggleSelectCity
}) => {
  // const topBorder = !!(index === 0 || index === 1 || index === 2);
  const renderFAElement = () => (
    <View style={styles.FADiv}>
      <View style={styles.FAIconBox}>
        <S3Image
          imageUrl={imageName}
          type={imageType}
          islazyLoadRequired={false}
          style={{ width: imageWidth, height: imageHeight }}
        />
      </View>
      <SText containerStyle={styles.FAname}>
        {VernacManager.translateString(FAname)}
      </SText>
    </View>
  );

  return (
    <View style={styles.FABlock}>
      {newTag && <S3Image name="new-tag" style={styles.FATag} />}
      {!isDashboard && location === "india" ? (
        <SClickableArea onClick={() => onToggleSelectCity(true, route, params)}>
          {renderFAElement()}
        </SClickableArea>
      ) : (
        <NavLink
          route={route}
          params={params}
          prefetch={false}
          onClick={() => {
            pushClevertapEvent("Homepage Clicks", {
              Type: FAname,
              Section: "Jobs by Category",
              Location: location || ""
            });
            sendPageClickEvent(FAname);
          }}
        >
          {renderFAElement()}
        </NavLink>
      )}
    </View>
  );
};

const FABlock = ({ FAlist, locationIs, isDashboard, onToggleSelectCity }) => {
  console.log("FA Block: ", FAlist);
  return (
    <View style={styles.container}>
      {FAlist &&
        FAlist.map((data, index) => (
          <Block
            key={`fa-block-${generateUniqueKey()}`}
            index={index}
            FAname={data.title}
            imageName={data.image}
            imageWidth={data.width}
            imageHeight={data.height}
            imageType={data.type}
            route={data.route}
            params={data.params}
            newTag={data.newTag}
            location={locationIs || ""}
            isDashboard={isDashboard}
            onToggleSelectCity={onToggleSelectCity}
          />
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start"
  },
  FABlock: {
    height: 100,
    width: "33.33%",
    paddingTop: 14,
    flexDirection: "column",
    backgroundColor: "#FFF"
  },
  FATag: {
    position: "absolute",
    top: 5,
    right: 6,
    height: 16,
    width: 39
  },
  FADiv: {
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  FAIconBox: {
    height: 40,
    width: 40,
    alignItems: "center",
    justifyContent: "center"
  },
  FAname: {
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    fontSize: 12,
    lineHeight: 16,
    color: colors.Grey[8],
    margin: 6
  },
  topBorder: {
    borderTopColor: colors.Grey[2],
    borderTopWidth: 1
  }
});

export default FABlock;
